@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/sizes"
@import "../../styles/responsive"

.backButton
  position: absolute !important
  left: 0
  .arrowBack
    cursor: pointer !important
    font-size: $title-font-size !important
    color: #000
    @media (min-width: $big-tablet)
      display: none !important