@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"

.dialogBackdrop
  background-color: rgba(0, 0, 0, 0.9) !important
.dialogRoot
  display: inline-flex !important
  flex-direction: column

.closeModalIcon
  position: absolute !important
  z-index: 10000 !important
  background-color: rgba(255, 255, 255, 0.57) !important
  padding: 6px !important
  top: 6px
  right: 6px
  color: $primary-color !important
.socialIconsContainer
  display: flex
  justify-content: center
  margin-top: 1rem
  .socialIconBox
    display: flex
    justify-content: center

.dialogContent
  display: flex
  flex-direction: column
  padding: 0 !important
  overflow: visible !important
  //overflow: hidden !important
.dialogContentText
  //height: 35vh
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  padding-right: 25px !important
  padding-left: 25px !important
  padding-top: 0.5rem

.cover
  position: relative
  height: 45vh
  height: 45%
  @media (max-height: 600px)
    height: 33vh
  @media (min-width: $computer)
    max-height: 46vh
  .coverImg
    width: 100%
    height: 100%
    object-fit: cover


  .coverNameContainer
    display: flex
    flex-direction: row
    align-items: center
    position: absolute
    bottom: 5px
    left: 15px
  .coverName
    color: white
    margin-left: 5px
    font-weight: bold
    font-size: $large-font-size
    text-shadow: 1px 2px 3px #000

.dialogActions
  margin-top: -2px !important //fixed visual breaking line bug
  padding-right: 25px !important
  padding-left: 25px !important
  padding-bottom: 1rem !important
.congratulations
  font-size: $big-font-size !important
  text-align: center
  font-weight: bolder !important
  margin-top: 1rem !important
  margin-bottom: 1rem !important
  @media (max-width: $small-mobile)
    font-size: 1.45rem !important
  @media (min-width: $tablet)
    margin-top: 0 !important
  @media (max-height: 600px)
    font-size: 1.25rem !important



.selledVideoCopy
  text-align: center
  font-size: 1.1rem !important
  @media (max-width: $small-mobile)
    font-size: 1rem !important


.showLogroButton, .showVideosButton
  font-weight: bold !important
  font-size: 1.15rem !important
  padding: 0.75rem !important
  background-color: white !important
  color: #ffffff !important
  border-radius: 15rem !important
  //margin-bottom: 1.25rem !important
  text-transform: none !important
  //box-shadow: 0 4px 5px darkgrey
  box-shadow: 0 4px 10px #787878
  @media (min-width: $tablet)
    font-size: 1rem !important
    margin-bottom: 1rem
  @media (max-height: 600px)
    padding: 0.45rem !important
    font-size: 0.95rem !important

.showVideosButton
  color: $primary-color !important
  background-color: #ffffff !important
  box-shadow: 0 4px 10px #787878 !important


.backDarkBlueColor
  background-color: $darkblue-color !important
.backPrimaryColor
  background-color: $primary-color !important
.backBlueColor4
  background-color: $blue-color !important
.backBlueColorLight
  background-color: #f0f9fe !important
.backBlueColor
  background-color: #5c8acf !important
.primaryColor
  color: $primary-color !important
.blueColor
  color: #5c8acf !important
.whiteColor
  color: white !important


.trophyImage
  width: 100%
  @media (min-width: $tablet)
    height: 80vh
    width: auto