@import "../../../styles/colors"
@import "../../../styles/typography"
@import "../../../styles/responsive"
@import "../../../styles/sizes"

.container
  display: flex
  justify-content: center
  @media (min-width: $big-tablet)
    padding: $paddingDesktopPage

.UploadVideo
  position: relative
  padding: $paddingPage
  display: flex
  justify-content: center
  flex-direction: column
  overflow: hidden
  @media (min-width: $tablet)
    width: 75%

  .titleVideoRequest
    font-weight: $regular-font-weight
    font-size: $medium-font-size
    text-align: center
    background-color: $blue-ligth-color
    border-left: 4px solid $blue-color
    padding: 8px 4px

  .form
    padding: 20px 5px
    display: flex
    flex-direction: column

    .addIcon
      width: 100%
      height: 100px
      path
        -webkit-filter: drop-shadow( 1px 1px 1px rgba(0, 0, 0, .2))
        filter: drop-shadow( 1px 1px 1px rgba(0, 0, 0, .2))

    .inputSelect
      margin-top: 16px
      margin-bottom: 16px

    .filepond
      color: #fff !important

      fieldset > div
        background-color: $primary-color !important

      button
        background-color: $blue-color !important

      span, svg
        color: #fff !important

      label
        font-size: 1.2rem
        white-space: pre-line
        width: 80%
        font-weight: 300
      label:hover
        cursor: pointer
  .form:hover
    cursor: pointer
  .progressBarContainer
    position: relative
    margin-bottom: 1rem

    .progressBarMask
      background-color: white
      width: 100%
      height: 3rem
      position: absolute
      z-index: 5

    .currentProgress
      background-color: $blue-color !important
      z-index: 4

    .progressBar
      background-color: $blue-ligth-color !important
      min-height: 3rem !important
      border-radius: 75px !important
      z-index: 6

    .progressBarPercent
      z-index: 6
      color: $primary-color
      position: absolute
      top: 50%
      left: 50%
      margin-top: -9px
      margin-left: -12px
      font-weight: bold

  .submitButton
    color: $white-color
    background-color: $blue-color
    border-radius: 200px
    padding: 10px
    margin-bottom: 1em
    @media (min-width: $tablet)
      margin-bottom: 2em


.isToRequestCheckbox, .buyableForAllCheckbox
  svg
    color: $blue-color

.disabledCheckbox
  svg
    color: #baa9bf !important

.buyableForAllClaim
  color: $blue-color

.helperModalContent
  padding: 0 !important
  @media (min-width: $tablet)
    padding: 1rem
.helperModalTitle
  padding-left: 1rem !important
  @media (min-width: $tablet)
    padding-left: 2rem !important
.helpersGrid
  justify-content: center
  @media (min-width: $tablet)
    padding: 1rem
.listItemHelper
  height: 14rem
  padding: 1rem
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-evenly
  img
    height: 70px !important
    width: 70px
  .listIcon
    min-height: 5rem
    width: 100%
    display: flex
    justify-content: center
    align-items: center
  .listText
    height: 4rem
    text-align: center
    font-size: $small-font-size
    @media (min-width: $tablet)
      font-size: $large-font-size
.textHelper
  font-size: $medium-font-size
  text-align: center
  font-weight: bold
  color: $blue-color !important
  text-decoration: underline
  margin: 0
.textHelper:hover
  color: $darkblue-color !important
  cursor: pointer
.arrowBack
  position: absolute !important
  top: 1em !important
  left: 1em !important
  cursor: pointer
  font-size: $title-font-size

.hurrify1
  margin: 1rem
  text-align: center
  color: #fff
  background-color: rgba(16, 33, 46, 0.73)
  border-radius: 8px
  padding: 0.5rem
  @media (min-width: $tablet)
    margin-left: 10%
    margin-right: 10%

.closeModalIcon
  position: absolute !important
  padding: 1rem
  z-index: 10000 !important
  top: 2px
  right: 2px
  @media(min-width: $tablet)
    top: 0.5rem
    right: 1rem
  svg
    color: $primary-color
