/*font-family*/
$font-family-primary: HelveticaNeue, sans-serif

/*font-size*/
$micro-font-size: 0.1rem
$tiny-font-size: 0.8rem
$small-font-size: 0.9rem
$medium-font-size: 1rem
$large-font-size: 1.2rem
$xl-font-size: 1.4rem
$big-font-size: 1.6rem
$title-font-size: 2rem
$big-title-font-size: 2.3rem
$huge-font-size: 2.8rem

/*font-weight*/
$light-font-weight: 100
$regular-font-weight: 300
$medium-font-weight: 400
$semi-bold-font-weight: 500
$bold-font-weight: bold

/* font shadows for high contrast */
$default-contrast-shadow: 0 0 5px #000000
