@import "../../../../../styles/colors"
@import "../../../../../styles/typography"
@import "../../../../../styles/responsive"
@import "../../../../../styles/sizes"

.root
  border: 1px solid $material-grey-color
  border-radius: 12px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  //height: 100%
  box-sizing: border-box
  padding: 1rem 2rem
  min-height: 10rem
  height: 100%

.contentWrapper
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  height: 100%

.iconContainer
  color: $material-grey-dark-color


.valueContainer
  color: $primary-color
  font-size: 2.15rem
  text-align: center
  font-weight: bold
  margin-top: auto

.textContainer
  color: $primary-color
  text-align: center
  margin-top: auto

//font-weight: 600
  //word-break: break-all


.chipTextContainer
  background-color: $blue-ligth-color
  color: $blue-color
  text-align: center
  font-size: 0.735rem
  padding: 0.25rem 1rem
  border-radius: 23rem
  font-weight: bold
  margin-top: auto
