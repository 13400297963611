@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"

.titleContainer
  display: flex
  justify-content: center
  margin-bottom: 1rem
  flex-direction: column
  padding: 1rem 28px
  @media (min-width: $big-tablet)
    padding: 0

  .title
    margin-bottom: 0
    font-size: $big-font-size
    @media (min-width: $big-tablet)
      font-size: $huge-font-size

  .subtitle
    margin-top: 1rem
    margin-bottom: 0
    font-size: $xl-font-size
    font-weight: $regular-font-weight
    @media (min-width: $big-tablet)
      font-size: $big-font-size
      margin-bottom: 2rem
