@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"


.noVideosMessageContainer
  display: flex
  flex-direction: column
  align-items: center
  width: 100% !important
  margin-bottom: 3rem
.noVideosImage
  width: 12rem
  @media (min-width: $tablet)
    width: 16rem

.noVideosMessage
  text-align: center
  margin-top: 0.5rem
  color: #fff,
  font-size: $large-font-size
  font-weight: 300
  @media (min-width: $tablet)
    width: 16rem
    font-size: $big-font-size
