@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"

.ContactUs
  padding: 0
  display: flex
  flex-direction: column
  @media (min-width: $big-tablet)
    padding: $paddingDesktopPage !important
  .subtitle
    text-align: center
    font-size: $large-font-size
    font-weight: $regular-font-weight
    margin: 0
  .content
    @media (max-width: $big-tablet)
      padding: 0 28px

  .form
    margin: 0 5px 20px 5px
    display: flex
    flex-direction: column
    align-items: center
    @media (min-width: $big-tablet)
      width: 22rem
      align-items: flex-start



  .submitButton
    color: $white-color
    background-color: $blue-color
    border-radius: 200px
    padding: 10px 2rem
    margin-top: 2rem
    box-shadow: none !important

.circularProgress
  position: absolute
  top: 50%
  left: 50%
  margin-top: -12px
  margin-left: -12px

.titleContainer
  display: flex
  justify-content: center
  margin-bottom: 1rem
  flex-direction: column
  padding: 0
  @media (min-width: $big-tablet)
    padding: 0
  h1
    margin-bottom: 0
    font-size: $big-font-size
    @media (min-width: $big-tablet)
      font-size: $huge-font-size
  h3
    margin-top: 1rem
    font-size: $xl-font-size
    font-weight: $regular-font-weight
    @media (min-width: $big-tablet)
      font-size: $big-font-size


.contactIllustration
  position: absolute
  bottom: 10%
  right: 10%
  width: 33%
  @media (max-width: $big-tablet)
    display: none
