@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"

.closeModalIcon
  position: absolute !important
  z-index: 10000 !important
  top: 2px
  right: 2px
.socialIconsContainer
  display: flex
  justify-content: center
  margin-top: 1rem
  .socialIconBox
    display: flex
    justify-content: center

.dialogContent
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
.congratulations
  text-align: center
  font-weight: bolder !important
  margin-top: 2rem !important

.selledVideoCopy
  text-align: center
  text-decoration-color: #00a3da !important
  text-decoration: underline
  margin-bottom: 2rem !important

.showLogroButton
  font-size: 1.15rem !important
  padding: 0.75rem 1.75rem !important
  background-color: $blue-color !important
  color: #ffffff !important
  border-radius: 15rem !important
  margin-bottom: 1.25rem !important

.trophyImage
  width: 100%
  @media (min-width: $tablet)
    height: 80vh
    width: auto