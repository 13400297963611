@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"

.container
  z-index: 1000 !important
  position: relative
  border-radius: 10px
  text-align: center
  box-shadow: -2px 7px 8px 5px #9e9e9e47
  margin-top: 20px
  margin-bottom: -2px
  align-self: center
  width: 90%
  padding: 0 5px
  @media (min-width: $tablet)
    width: 80%
  .text
    font-size: $tiny-font-size

.containerIn
  animation: formFading 2s

.containerOut
  opacity: 0
  animation: formFadingOut 1s

.arrowDown
  width: 0
  height: 0
  border-left: 20px solid transparent
  border-right: 20px solid transparent
  border-top: 20px solid #fff
  position: absolute
  bottom: -15px
  right: 43%


@keyframes formFading
  0%
    opacity: 0
  100%
    opacity: 1

@keyframes formFadingOut
  0%
    opacity: 1
  100%
    opacity: 0