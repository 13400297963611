@import "../../../styles/colors"
@import "../../../styles/typography"
@import "../../../styles/responsive"
@import "../../../styles/sizes"

.root
  display: flex
  justify-content: center
  align-items: center
  min-height: 100vh
  .formCard
    display: flex
    flex-direction: column
    align-items: center
    @media(min-width: $tablet)
      padding: 3rem 2rem 3rem
      max-width: 400px
      border: 2px solid $material-grey-color
      border-radius: 24px
    .logo
      width: 8rem
      height: 8rem
      //margin-bottom: -1rem
    .formCardContent
      width: 85%
      .loginButton
        margin-top: 2rem
        padding: 0.65rem 1rem
        background: $blue-color !important
        color: #ffffff !important
        border-radius: 33rem
        //border-bottom-left-radius: 0
        cursor: pointer !important
        font-weight: $semi-bold-font-weight !important
        text-transform: none !important
      .loginButton:disabled
        background-color: rgba(0, 0, 0, 0.12) !important

.footerTextContainer
  display: flex
  flex-direction: column
  font-weight: 300
  text-align: center
  margin-top: 2rem

.newPasswordLink, .registerLink
  color: $blue-color !important
  margin-left: 5px
  cursor: pointer
  text-decoration: underline
.circularProgress
  position: absolute
  top: 50%
  left: 50%
  margin-top: -12px
  margin-left: -12px



