@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"

.chip
  margin: 2px 5px
  background-color: #c9e3f4 !important
  color: #008bbf !important
  svg
    color: $blue-color !important

.chipsContainer
  display: flex
  justify-content: center
  flex-wrap: wrap
.textField
  width: 100%
  min-height: 4rem
  input
    min-height: inherit !important
    text-align: center
    padding-top: 0 !important
    padding-bottom: 2.1rem !important
    @media (min-width: $big-tablet)
      padding-bottom: 0 !important
  input::placeholder
    font-size: $tiny-font-size !important
    white-space: pre-line !important
  input::-webkit-input-placeholder
    white-space: pre-line !important
    position: relative
    top: -7px
  input::-moz-placeholder
    white-space: pre-line !important
    position: relative
    top: -7px
  input::-ms-input-placeholder
    white-space: pre-line !important
    color: #1d00ff !important
    position: relative
    top: -7px
  input::-moz-placeholder
    white-space: pre-line !important
    position: relative
    top: -7px
  label
    color: $primary-color !important
  div
    fieldset
      border-color: $primary-color !important
  .contentText
    border-bottom: 10px

.gridContainer
  margin: 32px 0 0 0 !important
  width: unset !important

.parentGrid
  flex-direction: row
  flex-wrap: wrap
  display: flex
  align-items: baseline
  border: 1px solid $primary-color !important
  border-radius: 5px

.typography
  margin-left: 5px !important
  margin-top: 2px !important


.label
  color: $primary-color !important
