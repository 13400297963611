@import "../../../../styles/colors"
@import "../../../../styles/typography"
@import "../../../../styles/responsive"
@import "../../../../styles/sizes"

.dialogMain
  border-radius: 0 !important

.loginSubtitle
  margin-top: 1rem !important
  margin-bottom: 1rem !important
  color: $primary-color !important
  max-width: 75%
  text-align: center
  //font-size: $big-font-size !important
  font-size: $large-font-size !important
  font-weight: $medium-font-weight !important
  //font-weight: $semi-bold-font-weight !important
  @media (max-width: $mobile)
    max-width: 85%
//font-size: $large-font-size !important
.dialogRootFix
  overflow-y: initial !important
.dialogTitle
  margin-top: 0 !important
  margin-bottom: 0 !important
.dialogContent
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  border-radius: 0 !important
  //padding-top: 3rem !important
.firstStepHeadCopy
  margin-top: 0
  text-align: center
  font-weight: 300
.roleButton
  text-transform: none !important
  border-radius: 8px !important
  margin: 1rem !important
  padding: 1rem 1.5rem !important
  min-height: 7.5rem !important
  color: $primary-color !important
  background-color: #fff !important
  //color: white !important
  //background-image: linear-gradient(75deg, $primary-color 23%, $blue-color 100%) !important
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22) !important
  .roleButtonIcon, .roleButtonIconFirst
    width: 40%
    display: flex
    img
      width: 4rem
      max-height: 5rem
      margin-right: auto
      margin-left: 5px
      align-self: flex-start
  .roleButtonIconFirst
    img
      width: 5rem
  .roleButtonText
    width: 60%
    line-height: 1.15rem
    font-size: $small-font-size
    font-weight: 500
    text-align: left
.form
  min-width: 70%

.rolSelector div
  white-space: normal !important

.rolOption
  white-space: normal !important


.socialLoginContainer
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  margin-top: 1rem
  .socialLoginPlatformIconContainer
    width: 10%
    display: inline-flex
    .socialLoginPlatformIcon
      width: 1.45rem
      align-self: flex-start
      max-height: 2rem
  .socialLoginPlatformName
    width: 80%
    color: $primary-color !important
  .googleButton
    //background-color: #dd4b39 !important
    //color: #fff !important
    background-color: #fff !important
    color: $primary-color !important
    text-transform: none !important
    padding-top: 0.75rem !important
    padding-bottom: 0.75rem !important
    //border: 0.5px solid $primary-color
    border-radius: 75px !important

  .facebookButton
    text-transform: none !important
    margin-top: 1rem
    //background-color: #1877F2 !important
     //color: #fff !important
       background-color: #fff !important
    background-color: #ffff !important
    color: #1877F2 !important
    //border: 1px solid #1877F2 !important
    border-radius: 75px !important
    padding-top: 0.75rem !important
    padding-bottom: 0.75rem !important


  .googleButton:disabled, .facebookButton:disabled
    background-color: #b9b9b9 !important

.waitingSocialLoginProgress
  margin-left: 15px !important
  color: #10212e !important

.insetFormDivider
  margin-top: 2rem
  margin-bottom: 0.5rem
  width: 100%
  display: flex
  flex-direction: row
  justify-content: center
  .dividerSection
    width: 20%
    border-bottom: 1px solid #b3acac
    margin-bottom: 9px
  .dividerText
    width: 20%
    //margin: 0 1rem
    text-align: center

.dialogActions
  width: 100%
  margin-top: 1rem
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin-bottom: 1rem
.actionsContainer
  margin-top: 2rem
  width: 100%
  @media (min-width: $tablet)
    //width: 85%




.submitButton
  padding: 10px !important
  background-color: $blue-color !important
  color: $white-color !important
  border-radius: 50px !important
  cursor: pointer !important
  font-weight: $semi-bold-font-weight !important
  text-transform: none !important
  margin: -5px 0 !important

.submitButton:disabled
  background-color: rgba(0, 0, 0, 0.12) !important

.goToRegisterButton
  background-color: $primary-color !important
  margin-bottom: 15px !important

.progressCircle
  width: 25px !important
  height: 25px !important
  font-size: $micro-font-size !important
  position: absolute
  top: 50%
  left: 50%
  margin-top: -12px
  margin-left: -12px

.authVerifiedErrorMessage
  padding: 0.5em !important
  text-align: center !important
  color: red !important
  @media (min-width: $tablet)
    max-width: 65% !important

.closeModalIcon
  position: absolute !important
  top: 2px
  right: 2px

.footerTextContainer
  display: flex
  flex-direction: row
  font-weight: 300
  text-align: center

.newPasswordLink
  color: $blue-color !important
  margin-left: 5px
  cursor: pointer
  //text-shadow: $default-contrast-shadow
  text-decoration: underline

.dialogMobileFullScreen
  @media (max-width: $tablet)
    //background: rgba(255, 255, 255, 0.8) !important

.dialogBackdrop
  @media (max-width: $tablet)
    background: transparent !important

.RegisterFormCardContent
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  @media (min-width: $tablet)
    width: 85%


.infoLOPDContainer
  background-color: $grey-ligth-color
  height: 10vh
  overflow: auto
  border-radius: 4px
  padding: 5px
  font-size: $small-font-size

.alertTerms
  background-color: $blue-color
  border-radius: 5px
  padding-left: 10px
  padding-right: 10px
  margin-top: 15px
  .alertTermsText
    color: white
    text-align: center
    font-size: $small-font-size
.termsCheck
  margin-top: 2.5em
  display: flex
  align-items: flex-start

.linkToTerms
  color: $primary-color
  text-decoration: none
  margin-left: 10px
  margin-top: 0
  a
    text-decoration: underline

.actionContainer
  display: flex
  flex-direction: column
  width: 90%
  @media (min-width: $tablet)
    width: 68%

.submitButton
  padding: 10px
  background-color: $primary-color
  color: $white-color
  border-radius: 50px
  cursor: pointer
  font-weight: $semi-bold-font-weight
  box-shadow: none
  margin-bottom: 15px
  @media (min-width: $tablet)
    margin-bottom: 27px

.submitButton:disabled
  color: rgba(83, 83, 83, 0.82) !important
  box-shadow: none
  background-color: $grey-ligth-color

.progressCircle
  width: 25px !important
  height: 25px !important
  font-size: $micro-font-size
  position: absolute
  top: 50%
  left: 50%
  margin-top: -12px
  margin-left: -12px

.activeTab
  background-image: linear-gradient(13deg, #a1c4fdd6 0%, #c2e9fb6e 100%)
.hurrify
  background-color: #ffff5a
  padding: 0.5rem 1rem
  text-align: center
  margin-bottom: 1rem !important
  max-width: 65%





