@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"

.gridItemCenteredContent:first-child
  @media(max-width: $tablet)
    padding-top: 0

.gridItemCenteredContent
  display: flex
  justify-content: center
  align-items: center
  padding: 8px 0

.addIcon
  margin-right: 0.5em
  width: 2rem
  height: auto


.card, .cardBIG
  width: 100%
  position: relative
  //border: 1px solid $darkblue-color
  border-radius: 8px !important
  //min-height: 55vh //with description
  //min-height: 36vh
  .videoCover
    height: 13rem
    @media (max-width: $tablet)
      height: 12rem

  .videoCardHeader
    padding: 28px 10px 10px 0

    span:first-child, span:nth-child(2)
      text-align: center

  .videoCardContent
    .videoCardContentText
      span
        font-weight: $medium-font-weight !important

      p
        font-weight: $regular-font-weight !important

  .videoCardActions
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center
    margin-bottom: 2rem

  .showRequestDetailButton
    background-color: $blue-color !important
    border-radius: 25px !important
    color: #ffffff !important
    width: 50% !important
    box-shadow: none
    padding: 10px
    @media (min-width: $big-tablet)
      position: absolute
      bottom: 3vh

  .deleteButton
    border-radius: 25px !important
    width: 50% !important
    margin: 10px

  .deleteIconButton
    position: absolute
    top: 0
    right: 0

    svg
      color: red !important

////////////////////////////////////////////////////////

.skeletonBox
  //height: 65vh
  min-height: 12rem
  max-height: 13rem
  border-radius: 10px
  align-items: center
  justify-content: center
  //margin: 1em !important
  span > span
    height: 100%
    border-radius: 10px

.videoRow
  transition: ease-in-out 0.15s

.videoRow:hover
  background-color: $blue-ligth-color-hover
  cursor: pointer

.videoThumbnailCellContainer
  height: 10rem

  .videoThumbnail
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.15), 0 0 6px rgba(0, 0, 0, 0.13)
    width: 100%
    height: 100%
    object-fit: cover
    border-radius: 12px
    object-position: center

  .videoThumbnail:hover
    cursor: pointer

.tagsContainer
  display: flex
  justify-content: center
  flex-wrap: wrap

  .tag
    padding: 5px 10px
    margin: 5px 5px 5px 0
    border: 1.2px solid $blue-color
    color: $blue-color !important
    border-radius: 5px

.downloadButtonMobile
  position: absolute !important
  bottom: 8px !important
  right: 8px !important
  background: $blue-color !important
  color: #ffffff !important
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)

.mobileVideoInfo
  display: none
  position: absolute
  width: 100%
  bottom: 0
  background-color: rgba(16, 33, 46, 0.51)
  .videoTitle
    color: #ffffff
    text-transform: capitalize
  .videoAuthor
    color: $blue-color
    text-transform: capitalize



.videoAuthorContainer
  display: flex
  align-items: center

.expansionIcon
  position: absolute !important
  //border-radius: 0 !important
  //padding: 0.5rem !important
  bottom: 0.25rem !important
  right: 0.25rem !important
  span
    //color: $blue-color !important


.collapsedInfo
  background-color: $primary-color
.tagAvatar
  background-color: $blue-color !important
.tagChip
  margin: 0.25rem 0.45rem
  color: $blue-color !important
  border-color: $blue-color !important

.actionsContainer
  position: absolute !important
  bottom: 0.5rem !important
  right: 0.25rem !important
  z-index: 700 !important
  display: flex
  align-items: center
  justify-content: flex-end
.actionButton
  //background-color: $blue-color !important
  background-color: #ffffff !important
  //padding: 8px !important
  margin: 0 0.25rem !important
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23) !important
  svg
    //color: #ffffff
    color: $blue-color !important
    font-size: 18px

.downloadsModalRoot
  transition: width 4s, height 4s ease-in-out !important
.chargingLinksBox
  color: $material-grey-color !important
  width: 100%
  padding-top: 1rem
.progressBarBackground
  background-color: $blue-ligth-color !important
.progressBar
  background-color: $primary-color !important


/////////
// ESTILOS HEREDADOS, TODO: Sacar los modales de descarga a un componente independiente
////////

.dialogHeader
  display: flex
  align-items: center
  //height: 3rem

  .closeModalIcon
    justify-self: flex-start

  svg
    color: $primary-color

.DetailVideo
  margin-top: 1rem
  padding-bottom: 3em !important
  @media (max-width: $tablet)
    padding-bottom: 6rem !important

  .floatingButtonContainer
    animation: slide-up 0.4s ease !important
    position: fixed
    bottom: 15px
    width: 50%
    right: 25%
    left: 25%
    @media (min-width: $tablet)
      display: none

    .floatingButton
      font-weight: $semi-bold-font-weight !important
      font-size: $medium-font-size !important
      background-color: $blue-color
      color: $white-color
      width: 100%
      @media (max-width: 435px)
        font-size: 0.8rem !important

    .actionButtonIcon
      color: #fff !important
      margin-right: 0.5em !important

  .errorText
    padding: 15px

  .headerContainer
    position: relative
    justify-content: flex-end
    padding: $paddingPage
    display: flex
    height: $headerContainer
    @media (min-width: $big-tablet)
      display: none

    .arrowBack
      cursor: pointer
      font-size: $title-font-size

    .locationContainer
      display: flex
      align-items: center


  .bodyContainer
    //padding: $paddingPage
    margin-top: 15px

    .deleteIcon
      font-size: $large-font-size

  .titleContainer
    position: relative
    display: flex
    justify-content: center
    align-items: center
    margin-top: 0.5rem
    margin-bottom: 0.5rem
    @media(min-width: $tablet)
      margin-top: 1rem

    .videoTitle
      width: 75% !important
      text-align: center
      font-size: $large-font-size
      font-weight: bold
      @media (min-width: $tablet)
        width: 50% !important

  .videoActions
    position: absolute
    right: 4rem
    display: flex
    justify-content: center
    align-items: center
    min-height: 3rem
    @media (max-width: $tablet)
      right: 0.15rem


  //.tagsContainer
    display: flex
    justify-content: center
    flex-wrap: wrap

    .tag
      padding: 5px 10px
      margin: 5px 5px 5px 0
      border: 1.2px solid $blue-color
      color: $blue-color !important
      border-radius: 5px

  .categoriesContainer
    display: flex
    flex-wrap: wrap
    justify-content: center

    .category
      padding: 0 5px 10px 0
      margin: 5px 5px 5px 0
      color: $darkblue-color !important
      text-decoration: underline
      cursor: pointer

  .tagsSectionTitle
    font-size: $small-font-size
    font-weight: bold !important

  .iframeStyle
    //background-color: black
    display: flex
    align-items: center
    iframe
      width: 100%
      border: none
      @media (min-width: $tablet)
        height: 350px
      @media (min-width: $computer)
        height: 450px

.downloadsModalContent
  display: flex
  flex-wrap: wrap
  justify-content: center
  @media (max-width: $big-tablet)
    flex-direction: column

.downloadClaim
  font-weight: bold
  margin-left: 1.5rem !important

.qualityDownloadButton
  min-height: 2rem
  animation: slide-in-left ease 0.4s !important
  margin: 0.25rem 0.5rem !important
  padding: 0.25rem 0.5rem !important
  border-color: #00a3da !important
  text-transform: lowercase !important
  @media (max-width: $tablet)
    margin: 1em !important

  a
    text-decoration: none

  .downloadIcon
    color: $blue-color !important
    margin-left: 0.5em


.freeDownloadOrPurchaseContent
  @media (max-width: $tablet)
    display: flex
    justify-content: space-evenly
    flex-direction: column

.freeDownloadOrPurchaseText
  color: $darkblue-color !important
  margin-bottom: 2em !important

.purchaseConfirmationActionsContainer
  display: flex
  justify-content: space-evenly !important
  padding-bottom: 0.8em !important
  flex-wrap: wrap
  @media (max-width: $tablet)
    flex-direction: column !important
    align-items: center !important

  .freeDownloadButton
    background-color: transparent !important
    border: 1px solid $blue-color !important
    border-radius: 75px !important
    color: $blue-color !important
    min-width: 17em !important
    box-shadow: none

    svg
      color: $blue-color
      margin-right: 0.5em

  .buyVideoButton
    background-color: $blue-color !important
    color: #ffffff !important
    min-width: 17em !important
    box-shadow: none
    @media (max-width: $tablet)
      margin-top: 1em

    svg
      color: #ffffff
      margin-right: 0.5em

.shareButtonBox
  display: flex
  justify-content: center
  margin-top: 1rem
  .shareButton
    border-radius: 3rem !important
    margin-left: 15px
    margin-right: 15px
    font-size: 18px
    svg
      margin-right: 0.25rem

.navigationButtonLeft
  z-index: 4000
  position: fixed !important
  bottom: 0.75rem
  left: 10px
  background-color: rgba(16, 33, 46, 0.84) !important
  @media (min-width: $tablet)
    top: 50%
    left: 10%
    bottom: unset


.navigationButtonRight
  z-index: 4000
  position: fixed !important
  bottom: 0.75rem
  right: 10px
  background-color: rgba(16, 33, 46, 0.84) !important
  @media (min-width: $tablet)
    top: 50%
    right: 11%
    bottom: unset

.navigationIcon
  font-size: 25px !important
  color: #fff !important
  @media (min-width: $tablet)
    font-size: 50px !important

.desktopActionButton
  height: 2.5rem
  width: 13rem !important
  box-shadow: none
  border-radius: 3em !important
  padding-left: 1.5em !important
  padding-right: 1.5em !important
  margin-right: 1em !important
  background-color: $blue-color !important
  color: #fff !important
  @media (max-width: $tablet)
    display: none

  .actionButtonIconDesktop
    //color: $blue-color !important
    color: #fff !important
    margin-right: 0.5em !important


.priceChip
  font-size: 1.15rem !important
  margin-right: 1.15rem
  margin-left: auto
  color: $primary-color
  @media(min-width: $tablet)
    margin-right: 0.5rem

  .euroIcon
    font-size: 1.15rem
    color: $primary-color

  .euroIcon:hover
    cursor: initial
    color: initial

  .priceChip:hover
    cursor: initial

  .priceChip:active
    background-color: initial

.desktopDownloadButton
  svg
    color: $blue-color !important
.favIcon, .downloadIconSkeleton
  svg
    color: $blue-color !important

.skeletonContainer
  width: 100%
  display: flex
  justify-content: center
  align-items: center

.closeModalDownloadIcon
  position: absolute !important
  right: 0 !important

.tagsContainer
  display: flex
  justify-content: center
  flex-wrap: wrap
  margin-top: 1.25rem !important
  .tagAvatar
    background-color: $blue-color !important
  .tagChip
    margin: 0.25rem 0.45rem
    color: $blue-color !important
    border-color: $blue-color !important
