@import "../../../styles/colors"
@import "../../../styles/typography"
@import "../../../styles/responsive"
@import "../../../styles/sizes"

.myVideos
  overflow-x: hidden
  display: flex
  flex-direction: column
  align-items: center
  margin-bottom: 2rem
  @media (max-width: $big-tablet)
    margin-bottom: 5rem
  @media (min-width: $big-tablet)
    padding: $paddingDesktopPage !important
  .title
    position: relative
    .iconInfoButton
      position: absolute
      top: 15%
      bottom: 60%
  .uploadedVideosContainer
    @media (min-width: $computer)
      margin: 0 16px
  .uploadedVideoGallery
    width: 100% !important
    margin-top: 0.2em !important
    @media (min-width: $tablet)
      padding: 1em !important
    .deleteIcon
      color: $white-color
    .videoTile
      border-radius: 10px
      height: 32vw !important
      @media (min-width: $tablet)
        height: 26.5vw !important
      @media (min-width: $computer)
        height: 22.5vw !important
    .videoTile>div
      border-radius: 10px
      transition: ease-in-out 0.25s
    .videoTile>div:hover
      @media (min-width: $computer)
        transform: scale(1.05)
        box-shadow: 0 0 8px 8px rgba(255, 255, 255, 0.86)
    .fixClickOnTile
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      z-index: 20
    .fixClickOnTile:hover
      cursor: pointer
  .thumbnail
    object-fit: cover

.waitingAnimation
  position: absolute
  top: 50%
  left: 50%
  margin-top: -12px
  margin-left: -12px
  @media (min-width: $tablet)
    left: 57%

.noVideosClaimContainer
  display: flex
  justify-content: center
  width: 100%
  text-align: center

.MuiGridListTileBar-title
  color: #ffffff !important


.videoCard
  padding: 8px 20px !important
  @media(min-width: $tablet)
    padding: 12px !important
