@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"

@keyframes slide-up
  0%
    opacity: 0
    transform: translateY(20px)

  100%
    opacity: 1
    transform: translateY(0)

.floatingButtonContainer
  animation: slide-up 0.4s ease !important
  position: fixed
  bottom: 15px
  width: 50%
  right: 25%
  left: 25%
  z-index: 1000
  @media (min-width: $tablet)
    display: none

  .UploadRetoButton
    font-weight: $semi-bold-font-weight !important
    font-size: $medium-font-size !important
    background-color: $blue-color !important
    color: $white-color
    width: 100%

