@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/sizes"
@import "../../styles/responsive"

.backButton
  position: absolute !important
  left: 0

  .arrowBack
    cursor: pointer !important
    font-size: $title-font-size !important
    color: #000
    @media (min-width: $big-tablet)
      display: none !important


.mainContainer
  position: fixed
  width: 100%
  box-sizing: border-box
  left: 0
  bottom: 0
  z-index: 4050
  padding: 1rem
  color: $primary-color
  background-color: #fff
  box-shadow: 0 0 36px -4px rgba(125,125,125,1)
  min-height: 5rem
  display: flex
  flex-direction: column
  justify-content: space-between
  @media(min-width: $tablet)
    align-items: center
    flex-direction: row
    padding: 1rem 3rem

.bannerText
  font-weight: 300
  color: $primary-color
  line-height: 1.5rem
  vertical-align: center
  font-size: 0.85rem
  @media(min-width: $tablet)
    width: 75%
  a
    margin-left: 0.25rem
    font-weight: 300
    color: $primary-color
.bannerActions
  display: flex
  justify-content: flex-end
  align-items: center
  @media(max-width: $tablet)
    margin-top: 0.75rem
    margin-right: unset !important

.cookiesSettingsButton
  color: $primary-color!important
  border-radius: 0px !important
  font-size: 0.8rem !important
  text-transform: none !important
  span:first-child
    line-height: 24px !important
    border-bottom: 1px solid #000 !important

.cookiesAcceptAllButton
  margin-left: 0.75rem !important
  color: #ffffff !important
  font-weight: 300 !important
  padding: 0.5rem 1.5rem !important
  background: $primary-color !important
  border-radius: 255px !important
  text-transform: none !important


.cookieTypeTitle
  display: flex !important
  justify-content: space-between !important
  //margin-top: 0.5rem !important
  font-size: 1.15rem !important
.cookiesSectionDescription
  font-weight: 200
  line-height: 22px
.cookiesSectionDivider
  margin-top: 1.5rem !important
  margin-bottom: 1.5rem !important
.settingsModalActions
  border-top: 1px solid $grey-ligth-color !important
  padding-top: 3rem !important
  padding-bottom: 2rem !important

.savePreferencesCookiesButton
  text-transform: capitalize !important
  span:first-child
    line-height: 24px !important
    border-bottom: 1px solid #000 !important
.settingsModalAcceptAllCookiesButton
  color: #ffffff !important
  background: $primary-color !important
  border-radius: 355px !important
  text-transform: capitalize !important

.cookiesSettingsModalPaper
  border-radius: 24px !important
  padding: 1rem
.cookiesSettingsModal
  z-index: 4070 !important
