@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"

.categoriesRoot
  overflow: hidden
  //padding: 24px
.categories
  padding: 15px 8px
  display: flex
  justify-content: center
  @media (min-width: $big-tablet)
    padding: $paddingDesktopPage !important
    margin-bottom: 5em !important
  .circularProgress
    margin-top: 50px
  .gridCardItem
    @media (max-width: $tablet)
      padding: 4px 8px !important
.searchContainer
  display: flex
  justify-content: center