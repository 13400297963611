@import "../../../styles/colors"
@import "../../../styles/typography"
@import "../../../styles/responsive"
@import "../../../styles/sizes"


.root
  width: 100%
  position: relative !important
  display: flex
  flex-direction: column
  border-radius: 14px !important
  flex: 0 0 14rem
  margin: 0 0.75rem
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1)
  //border: 1px solid $material-grey-color


.root:hover
  cursor: pointer
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important

.mainImage
  position: relative
  background-color: #000
  //min-height: 13rem
  display: flex !important
  justify-content: center !important
  align-items: center !important
  aspect-ratio: 10/9
  box-shadow: inset 0 0 0 1000px rgb(9 45 56 / 9%)
  @media(min-width: $computer-2k)
    //height: 22rem

.categoryChip
  position: absolute
  top: 1rem
  left: 1rem
  padding: 0.5rem 1rem
  border: 1px solid #000
  border-radius: 355px
  font-weight: 400
  background: #fff
  text-transform: uppercase
  color: #000

.title
  //padding-top: 12px !important
  padding-bottom: 0 !important
  line-height: 1.2 !important
  display: -webkit-box !important
  -webkit-line-clamp: 2 !important
  /*! autoprefixer: off */
  -webkit-box-orient: vertical !important /* stylelint-disable-line property-no-vendor-prefix */
  /*! autoprefixer: on */
  overflow: hidden
  font-size: 1.2rem !important
  font-weight: 500 !important

.subtitle
  font-size: 1rem !important

.actions
  display: flex
  justify-content: space-between !important
  align-items: flex-end !important
  padding: 8px 1rem !important
  margin-top: 0.65rem
  margin-bottom: 0.5rem


.linearProgress
  background-color: $material-grey-color !important
.linearProgressBar
  background-color: #fff !important


