@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"

.iframe
  width: 100%
  min-height: 34rem
  border: none
  overflow: hidden
.terms
  padding: 1rem
  @media (min-width: $tablet)
    padding: 5rem
  .table
    width: 100%
    th, td
      border: 1px solid black
      text-align: center
