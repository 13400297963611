@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"

.RequestPasswordChange
  min-height: 100vh
  display: flex
  //flex-direction: column
  align-items: center
  justify-content: center
  color: $white-color
  //background: url('/img/png/fondo-login.png') no-repeat center
  background-size: cover
  @media (min-width: $tablet)
    //background: url('/img/png/fondo-login-desktop.png') no-repeat center

  .card
    display: flex
    flex-direction: column
    align-items: center
    @media(min-width: $tablet)
      padding: 2rem 2rem 3rem
      max-width: 400px
      border: 2px solid $material-grey-color
      border-radius: 8px
    .logo
      width: 11rem
      height: 11rem
      //margin-bottom: 1rem
    .title
      text-align: center
      font-weight: bold
      font-size: $title-font-size !important
      line-height: 1em
      margin-top: 25px
      @media (min-width: $tablet)
       margin-top: 35px
    .subtitle
      text-align: center
      font-size: $large-font-size
      font-weight: $regular-font-weight
      margin-top: 0
      margin-bottom: 0


.cardContent
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  width: 80%
  @media (min-width: $tablet)
    width: 85%

.cardActions
  display: flex
  justify-content: center
  width: 100%


.loginLink
  text-shadow: $default-contrast-shadow

.loginLink > a
  margin-left: 0.2em
  text-decoration: underline
  color: #ffffff
  text-shadow: $default-contrast-shadow

.form


.confirmationMessage
  font-size: $small-font-size
  color: $blue-color !important
  text-align: center

.actionButton
  margin-top: 2rem !important
  padding: 10px !important
  background-color: $blue-color !important
  font-weight: $semi-bold-font-weight
  box-shadow: none !important
  border-radius: 33rem !important
  //border-bottom-left-radius: 0 !important
  text-transform: none !important

.actionButton:disabled
  background-color: rgba(0, 0, 0, 0.12) !important

.circularProgress
  position: absolute
  top: 50%
  left: 50%
  margin-top: -12px
  margin-left: -12px
