@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"
*
  //This fix the blue overlay when clicking some elements on some navigators
   -webkit-user-select: none
   -moz-user-select: none
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0)


.videoCard
  width: 100%
  position: relative !important
  display: flex
  flex-direction: column
  border-radius: 18px !important
  height: 100%
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1)
  border: 1px solid $material-grey-color


.videoCard:hover
  cursor: pointer
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important

.videoCardCover
  background-color: #000
  position: relative
  height: 19rem
  display: flex !important
  justify-content: center !important
  align-items: center !important
  aspect-ratio: 9/9
  @media(min-width: $computer-2k)
    height: 22rem

.alternativeMediaCardCover
  position: relative
  background-color: #000
  height: 19rem
  display: flex !important
  justify-content: center !important
  align-items: center !important
  aspect-ratio: 9/9
  @media(min-width: $computer-2k)
    height: 22rem

.videoCardContent
  flex: 1 !important

.videoCardTitle
  padding-top: 12px !important
  padding-bottom: 0 !important
  line-height: 1.2 !important
  display: -webkit-box !important
  -webkit-line-clamp: 2 !important
  /*! autoprefixer: off */
  -webkit-box-orient: vertical !important /* stylelint-disable-line property-no-vendor-prefix */
  /*! autoprefixer: on */
  overflow: hidden
  font-size: 1.4rem !important
  font-weight: 500 !important





.videoCardAuthor
  //line-height: 2rem
  //color: #797979
  //text-transform: capitalize
  font-size: 1rem !important

.playIcon, .playIcon2
  color: #fff
  width: 5em
  height: 5em
//font-size: 5.75rem !important
//@media(min-width: $tablet)
//  font-size: 7.5rem !important
.playIcon2
  position: absolute
  top: 50%

.playIconFloating
  color: #fff
  width: 5em
  height: 5em
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  z-index: 200

.mediaTypeImageIcon
  color: $white-color
  width: 2rem !important
  height: 2rem !important
  position: absolute
  bottom: 0
  right: 0

.videoCardActions
  display: flex
  padding: 8px !important
  margin-top: 0.65rem
  justify-content: center !important
  margin-bottom: 0.5rem


.actionButton, .actionButtonFilled
  color: $blue-color !important
  border: 1px solid $blue-ligth-color !important

  //margin: 0.5rem !important
  //padding: 8px !important
  svg
    height: 24px
    width: 24px

.actionButtonFilled
  background: $blue-ligth-color !important

  svg
    height: 24px
    width: 24px
    color: $blue-color

.actionIcon
  color: $complementary-concrete-color
  font-size: 26px !important

.primaryColor
  color: $blue-color !important
  fill: $blue-color !important

.filledIcon
  color: $blue-color
  background-color: $blue-ligth-color

.waitingLinkCircle
  color: $blue-color !important
  height: unset !important
  width: unset !important

.actionCounter
  font-size: 18px !important
  color: $complementary-concrete-color


.downloadsModalContent
  display: flex
  flex-direction: column
  @media (min-width: $tablet)
    padding: 2rem 3rem !important
    justify-content: center

.downloadClaim
  font-weight: bold
  margin-left: 1.5rem !important

.qualityDownloadButton
  min-height: 2rem
  animation: slide-in-left ease 0.4s !important
  margin: 0.5rem 0.5em !important
  border-radius: 0 !important
  border: 2px solid $blue-color !important
  text-transform: none !important
  color: $blue-color !important
  @media (max-width: $tablet)
    margin: 1em !important

  a
    text-decoration: none

  .downloadIcon
    color: $blue-color !important
    margin-right: 0.5em

.desktopDownloadButton
  svg
    color: $blue-color !important

.favIcon, .downloadIconSkeleton
  svg
    color: $blue-color !important

.skeletonContainer
  width: 100%
  display: flex
  justify-content: center
  align-items: center

.downloadLinksModalRoot
  border-radius: 12px !important
  box-shadow: 0 0 15px #fff !important

.closeModalDownloadIcon
  position: absolute !important
  right: 0 !important
  color: $primary-color !important

.linearProgress
  position: absolute !important
  top: 0 !important
  width: 100% !important
  border-top-left-radius: 16px !important
  height: 0.25rem !important
  background-color: $material-grey-color !important

.linearProgressBar
  background-color: $blue-color !important


.alternativeCardActions
  display: flex
  padding: 8px !important
  margin-top: auto
  justify-content: flex-end !important

.showMoreBtn
  border-radius: 255px !important
  color: $white-color !important
  background-color: $primary-color !important
  text-transform: none !important
  font-weight: bold !important
  width: 5rem
  padding-top: 4px !important
  padding-bottom: 4px !important
  margin: 0.45rem auto 0.45rem 8px !important
.showMoreBtnFullwidth
  margin: 0.45rem 8px 0.45rem 8px !important
  width: 100%

.alternativeActionButton
  margin-left: unset !important
.alternativeActionButton:hover
  background: unset !important
  //background: $blue-ligth-color-hover-2 !important

.alternativeLikeButton
  display: flex !important
  flex-direction: column !important
  align-items: center !important
  text-transform: lowercase !important
  border-radius: 255px !important
.alternativeLikeButton:hover
  //background: $blue-ligth-color-hover-2 !important
  background: unset !important

.shareIconWrapper
  display: flex
  flex-direction: column
  align-items: center
.shareIcon
  width: 1.3rem
  height: 1.25rem
  fill: #000000
  margin-bottom: 0.2rem


.alternativeActionIcon
  color: $primary-color

.alternativeActionCounter
  font-size: 18px !important
  color: $primary-color
  min-width: 10px

.actionTitle
  font-size: 0.7rem
  color: #000
  font-weight: 500

.actionButtonCounterWrapper
  display: flex
  justify-content: center
  align-items: center
  margin-bottom: -8px
