@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/sizes"
@import "../../styles/responsive"

.dialogRoot
  z-index: 8001 !important

.messageContent

.messageBox
  background: #fafafa !important
  position: relative
  top: 22%
  padding: 1rem 1.75rem
  border: 1px solid #dedede
  color: $primary-color
  font-size: larger
  text-align: center
  font-weight: 500
  line-height: 1.8rem
.messageCtaImg
  position: absolute
  top: 0.5rem
  width: 70%
  right: 0.25rem