@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"


//Animations
@keyframes slide-in-left
  0%
    opacity: 0
    transform: translateX(-20px)

  100%
    opacity: 1
    transform: translateX(0)

@keyframes slide-up
  0%
    opacity: 0
    transform: translateY(20px)

  100%
    opacity: 1
    transform: translateY(0)
////////////////////////////////////////////////

.DetailVideo
  padding-bottom: 6em !important
  @media (min-width: $big-tablet)
    padding: $paddingDesktopPage
  .floatingButtonContainer
    animation: slide-up 0.4s ease !important
    position: fixed
    bottom: 15px
    width: 50%
    right: 25%
    left: 25%
    @media (min-width: $tablet)
      display: none
    .floatingButton
      font-weight: $semi-bold-font-weight !important
      font-size: $medium-font-size !important
      background-color: $blue-color
      color: $white-color
      width: 100%
      @media (max-width: 435px)
        font-size: 0.8rem !important
    .actionButtonIcon
      color: #fff !important
      margin-right: 0.5em !important
  .errorText
    padding: 15px
  .headerContainer
    position: relative
    justify-content: flex-end
    padding: $paddingPage
    display: flex
    height: $headerContainer
    @media (min-width: $big-tablet)
      display: none
    .arrowBack
      cursor: pointer
      font-size: $title-font-size
    .locationContainer
      display: flex
      align-items: center
  .bodyContainer
    padding: $paddingPage
    margin-top: 15px
    .deleteIcon
      font-size: $large-font-size
  .titleContainer
    display: flex
    justify-content: space-between
    align-items: center
    .videoTitle
      width: 75% !important
      font-size: $large-font-size
      font-weight: bold
  .videoActions
    display: flex
    justify-content: center
    align-items: center
    @media (max-width: $tablet)
      flex-direction: column-reverse
  .desktopActionButton
    height: 2.5rem
    width: 13rem !important
    box-shadow: none
    border-radius: 3em !important
    padding-left: 1.5em !important
    padding-right: 1.5em !important
    margin-right: 1em !important
    background-color: $blue-color !important
    color: #fff !important
    @media (max-width: $tablet)
      display: none
    .actionButtonIconDesktop
      //color: $blue-color !important
      color: #fff !important
      margin-right: 0.5em !important
  .tagsContainer
    display: flex
    flex-wrap: wrap
    .tag
      padding: 5px 10px
      margin: 5px 5px 5px 0
      border: 1.2px solid $blue-color
      color: $blue-color !important
      border-radius: 5px
  .categoriesContainer
    display: flex
    flex-wrap: wrap
    .category
      padding: 10px 5px 10px 0
      margin: 5px 5px 5px 0
      color: $darkblue-color !important
      text-decoration: underline
      cursor: pointer
  .tagsSectionTitle
    font-size: $small-font-size
    font-weight: bold !important
  .iframeStyle
    background-color: black
    display: flex
    align-items: center
    iframe
      width: 100%
      border: none
      @media (min-width: $tablet)
        height: 350px
      @media (min-width: $computer)
        height: 450px

.downloadsModalContent
  display: flex
  padding-left: 3rem
  padding-right: 3rem
  padding-bottom: 1rem !important
  @media (max-width: $tablet)
    flex-direction: column

.downloadClaim
  font-weight: bold
  margin-left: 1.5rem !important
.qualityDownloadButton
  min-height: 2rem
  animation: slide-in-left ease 0.4s !important
  margin: 0 0.5em !important
  border-color: #00a3da !important
  text-transform: lowercase !important
  @media (max-width: $tablet)
    margin: 1em !important
  a
    text-decoration: none
  .downloadIcon
    color: $blue-color !important
    margin-left: 0.5em
.closeModalIcon
  position: absolute !important
  top: 2px
  right: 2px

.priceChip
  font-size: 1.55rem !important
  margin-right: 0.5rem
  margin-left: 0.5rem
  color: $primary-color
  @media(max-width: $tablet)
    font-size: initial !important
  .euroIcon
    color: $primary-color
    @media(max-width: $tablet)
      //font-size: $large-font-size
  .euroIcon:hover
    cursor: initial
    color: initial

.priceChip:hover
  cursor: initial
.priceChip:active
  background-color: initial


.freeDownloadOrPurchaseContent
  @media (max-width: $tablet)
    display: flex
    justify-content: space-evenly
    flex-direction: column
.freeDownloadOrPurchaseText
  color: $darkblue-color !important
  margin-bottom: 2em !important
.purchaseConfirmationActionsContainer
  display: flex
  justify-content: space-evenly !important
  padding-bottom: 0.8em !important
  flex-wrap: wrap
  @media (max-width: $tablet)
    flex-direction: column !important
    align-items: center !important
  .freeDownloadButton
    background-color: transparent !important
    border: 1px solid $blue-color !important
    border-radius: 75px !important
    color: $blue-color !important
    min-width: 17em !important
    box-shadow: none
    svg
      color: $blue-color
      margin-right: 0.5em
  .buyVideoButton
    background-color: $blue-color !important
    color: #ffffff !important
    min-width: 17em !important
    box-shadow: none
    @media (max-width: $tablet)
      margin-top: 1em
    svg
      color: #ffffff
      margin-right: 0.5em
.shareButtonBox
  display: flex
  justify-content: center
  margin-top: 2rem
  .shareButton
    margin-left: 15px
    margin-right: 15px
