@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"

@keyframes slide-up
  0%
    opacity: 0
    transform: translateY(20px)
    -moz-transform: translateY(20px)

  100%
    opacity: 1
    transform: translateY(0)
    -moz-transform: translateY(0)
    -o-transform: translateY(0)

@keyframes zoomIn
  0%
    opacity: 0
    transform: scale(0)
    -moz-transform: scale(0)
    -o-transform: scale(0)

  100%
    opacity: 1
    transform: scale(1)
    -moz-transform: scale(1)
    -o-transform: scale(1)


.mainGrid
  background-color: #06232c
  justify-content: center
  @media(min-width: $tablet)
    //padding-left: 0.5rem
    //padding-right: 0.5rem

.backButton
  justify-self: flex-start
  align-self: flex-start
  background-color: #ffffff !important

.gradientWrapper
//background-image: linear-gradient(180deg, #FBAB7E 0%, #ffffff 100%)
//max-height: 31rem

.DetailRoot
  //background: $grey-ligth-color
  //margin-top: $navbarHeight
  background-color: #06232c
  justify-content: center
  display: flex
  flex-direction: column
  @media (max-width: $tablet)
    padding-bottom: 6.5rem
  @media(min-width: $tablet)
    //padding: $paddingConcursoPageWithAds





  .requestTitleAndTime
    margin-left: 1rem !important

  .requestTitle
    font-weight: bold !important
    font-size: $large-font-size !important
    line-height: 1.4rem
    @media(min-width: $tablet)
      font-size: $big-title-font-size !important
      line-height: 2.4rem
      margin-right: 2.5rem

  .requestSubtitle
    @media(min-width: $tablet)
      font-size: $medium-font-size !important

  .headImage
    max-width: 100%
    display: flex
    justify-content: center
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1)
    width: 100%
    @media(min-width: $tablet)
      border-bottom-left-radius: 12px
      border-bottom-right-radius: 12px
    @media(min-width: $computer-xl) and (max-width: $uhd-screen)
      max-height: 34rem
      object-fit: cover

.videoActionsButtons
  display: flex
  justify-content: center

  //.mainActionButton
  //  color: $white-color !important
  //  background-color: $blue-color !important
  //  text-transform: none !important
  //  border-radius: 200px !important
  //  padding: 5px 25px !important
  //  margin-top: 0.5rem !important
  //  margin-bottom: 0.5rem !important
  //  font-size: $large-font-size !important
  //  z-index: 1290 !important
  //  transition: 0.5s
  //  animation: slide-up 0.4s ease !important
  //  position: fixed !important
  //  bottom: 0.7rem
  //  font-weight: 500
  //  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important
  //  //font-weight: 300
  //  @media(min-width: $tablet)
  //    right: 1rem
  //    bottom: 2rem

  //.mainActionButtonMinimized
  .mainActionButton
    z-index: 1290 !important
    transition: 0.5s
    animation: slide-up 0.4s ease !important
    background-color: $blue-color !important
    color: #ffffff !important
    padding: 5px 25px !important
    border-radius: 375px !important
    position: fixed !important
    font-weight: 500
    font-size: $large-font-size
    //font-weight: 300
    bottom: 1em
    right: 1rem
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important
    text-transform: unset !important


.videoActionsButtonsDesktop
  margin-right: 3rem
  @media (max-width: $big-tablet)
    display: none

  .mainActionButtonDesktop
    background-color: $blue-color !important
    color: #ffffff !important
    border-radius: 25px !important
    min-width: 15rem
    padding: 1em 2em !important
    box-shadow: none !important


.videoGalleryContainer
  display: flex
  flex-wrap: wrap
  justify-content: space-around
  overflow: hidden

.videoGallery
  width: 100% !important
  margin-top: 0.2em !important
  padding: 0.5em !important
  max-height: 75vh
  @media (min-width: $tablet)
    padding: 1em !important

.uploadVideoTile
  animation: zoomIn 0.6s ease !important
  height: 100%
  color: #ffffff
  background-color: $blue-color
  display: flex !important
  flex-direction: column
  justify-content: center
  align-items: center !important
  text-align: center
  cursor: pointer

.uploadVideoText
  color: $white-color !important
  font-weight: $semi-bold-font-weight

.uploadVideoIcon
  color: $white-color !important
  font-size: 3.5em !important

.headerTile
  width: 100%
  box-shadow: 0 7px 5px 0 rgba(0, 0, 0, 0.25)
  z-index: 400

.videoGalleryHeader
  height: auto !important
  position: sticky !important

.videoGalleryHeader > div
  display: flex
  align-items: center

.videoTile > div
  animation: zoomIn 0.6s ease !important
  transition: ease-in-out 0.25s
  background-color: $primary-color
  display: flex
  justify-content: center
  align-items: center

.videoTile > div:hover
  cursor: pointer
  @media (min-width: $computer)
    //transform: scale(1.05)
    box-shadow: 0 0 8px 8px rgba(255, 255, 255, 0.86)

.tileSelectedOverlay
  z-index: 100
  display: flex
  justify-content: center
  align-items: center
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: transparentize($blue-color, 0.6)
  transition: ease-in-out 0.5s

  .checkIcon
    font-size: 6em !important
    opacity: 1 !important
    position: absolute !important
    color: $white-color !important

.fixClickOnTile
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%

.fixClickOnTile:hover
  cursor: pointer

.closeDrawerButton
  z-index: 1000 !important
  position: absolute !important
  top: 0
  right: 0

.uploadedVideoGallery
  width: 100% !important
  margin-top: 0.2em !important
  padding: 0.5em !important
  @media (min-width: $tablet)
    padding: 1em !important

.uploadedVideosContainer
  margin: 15px
  justify-content: flex-start
  @media (max-width: $tablet)
    justify-content: center

.sendVideoButton
  animation: slide-up 0.4s ease !important
  position: absolute !important
  right: 4em !important
  color: #ffffff !important
  background-color: $blue-color !important
  box-shadow: none !important
  border-radius: 50px !important

.sendIcon
  color: $white-color !important
  margin-left: 0.5em

.progressCircle
  color: $blue-color
  position: absolute
  top: 50%
  left: 50%
  margin-top: -12px !important
  margin-left: -12px !important

.thumbnail
  display: flex
  justify-content: center
  background-color: black
  height: 100%
  object-fit: cover

.shareButtonBox
  display: flex
  justify-content: center
  margin-top: 2rem

  .shareButton
    margin-left: 15px
    margin-right: 15px

.toolBar
  justify-content: space-between !important
  padding-left: 1.35rem !important
  padding-right: 1.35rem !important
  @media (min-width: $big-tablet)
    padding-left: 20% !important
    padding-right: 20% !important

.navbarEntityLogo
  height: 2.5rem

.profileIcon
//margin-left: auto !important

.uploadVideoButton
  font-weight: $semi-bold-font-weight !important
  font-size: $medium-font-size !important
  background-color: $blue-color !important
  color: $white-color !important
  border-radius: 4rem !important
  box-shadow: none !important

.fixTabsFlex
  //justify-content: center !important
  align-items: baseline !important

.tabsContainer
  align-items: center
  //padding-left: 1.5rem !important
  //padding-right: 1.5rem !important
  @media(min-width: $tablet)
//padding-left: 1.5rem !important
//padding-right: 1.5rem !important

.tabButton:last-child
  margin-right: 16px !important

.tabButton:first-child, .tabButtonSelected:first-child
  margin: 0 !important
  @media(max-width: $tablet)
    margin-left: 16px !important

.tabButton, .tabButtonSelected
  text-transform: capitalize !important
  box-shadow: none !important
  min-height: 32px !important
  height: 32px !important
  border-radius: 355px !important
  font-weight: 600 !important
  margin-left: 0.5rem !important
  max-width: unset !important
  align-items: unset !important
  @media(min-width: $tablet)
    padding: 20px 32px !important

.tabButton
  //border: 1px solid $blue-color !important
  color: $primary-color !important
  background-color: #91909e24 !important

.tabButtonSelected
  font-weight: bolder !important
  background-color: $primary-color !important
  color: $white-color !important

.tabRoot
  max-width: unset !important
  min-width: unset !important
  font-size: 0.75rem !important
  @media(min-width: $tablet)
    padding-left: 2.5rem !important
    padding-right: 2.5rem !important
    font-size: 0.875rem !important

  img
    max-width: 100% !important

.tabColor
  color: #000000 !important

.tabPanel
  @media(min-width: $tablet)
    //margin-left: 2.5rem
  @media(max-width: $tablet)
    margin-top: -1.95rem



.requestDescriptionContainer
  display: flex
  flex-direction: column
  align-items: center

  div:first-child
    width: 100%

  .requestTermsLink
    text-align: center
    margin-top: 1rem
    margin-bottom: 1.25rem
    color: $blue-color !important


.descriptionExpansorButton
  color: #797979 !important
  text-transform: none !important
  @media(min-width: $tablet)
    margin-top: 0.5rem !important

.contentBox
  margin-top: 1rem
  @media(min-width: $tablet)
    margin-top: 2rem
    padding-left: 3.5rem
    padding-right: 3.5rem


.chipTabsBox
  @media(max-width: $tablet)
    padding: 0 24px


.eventsContainer
  display: flex
  flex-direction: column
  padding: 1rem 1.35rem
  @media(min-width: $tablet)
    padding: 0 0 2rem
    //margin-top: -0.75rem


.videoCard
  position: relative !important
  display: flex
  flex-direction: column
  border-radius: 12px !important
  height: 100%
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1)

.videoCard:hover
  cursor: pointer
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important

.videoCardCover
  height: 12rem
  display: flex !important
  justify-content: center !important
  align-items: center !important

.videoCardContent
  flex: 1 !important

.videoCardTitle
  font-size: $xl-font-size

.videoCardAuthor
  line-height: 2rem
  color: #797979

.playIcon
  color: #fff
  font-size: 7.5rem !important

.videoCardActions
  margin-top: auto
  justify-content: flex-end !important

.actionIcon
  margin-right: 5px
  color: $complementary-concrete-color
  font-size: 18px !important

.actionCounter
  font-size: 18px !important
  color: $complementary-concrete-color

.paginationContainer
  display: flex
  justify-content: center

.footerDivider
  margin-top: 2rem !important
  background-color: #8d8b8b !important

.footer
  color: #fff
  display: flex
  align-items: center
  flex-direction: column
  padding-top: 1rem
  padding-bottom: 2rem
  text-align: center

  .footerImage
    height: 3.23rem

  .footerLawContainer
    display: flex
    flex-direction: column
    line-height: 1.3rem
    @media(min-width: $tablet)
      flex-direction: row

  .footerSession
    margin-top: 10px

  .footerCloseSession
    text-decoration: underline
    margin-top: 10px
    color: $material-grey-color
    cursor: pointer

.HtmlParserContent, .HtmlParserContentShort, .HtmlParserContentAdvices
  iframe
    border: none !important
  iframe[class*="tiktok"]
    padding: 1rem
  iframe:not([class*="tiktok"])
    width: 100% !important
    border: none !important

  img
    width: 100% !important
    //border-radius: 24px
    @media(min-width: $tablet)
      width: 75% !important

.HtmlParserContentShort
  //display: -webkit-box !important
  ///*! autoprefixer: off */
  //-webkit-box-orient: vertical !important /* stylelint-disable-line property-no-vendor-prefix */
  ///*! autoprefixer: on */
  //-webkit-line-clamp: 4 !important
  ////-moz-box-orient: vertical !important
  //overflow: hidden

.HtmlParserContentAdvices
  p
    overflow: hidden !important

  //iframe:first-child
  //  @media(max-width: $tablet)
  //    margin-top: -5rem !important
  //    margin-bottom: -6.5rem !important

.testBox
  display: none
  position: fixed
  top: 1rem
  border-radius: 12px
  right: 1rem
  background: #d7f8ff
  padding: 1rem
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%)
  z-index: 10

.mainSelectorsContainer
  margin-bottom: 1.75rem
  //margin-top: 1.75rem
  margin-top: 0.9rem
  display: flex
  justify-content: flex-start
  align-items: center
  @media(max-width: $tablet)
    justify-content: center

.filtersBox
  //display: flex
  //justify-content: center
  //flex-wrap: wrap
  //padding-bottom: 1rem
  display: flex
  align-items: center
  //border: 1px solid #bdbdbd
  padding: 0.2rem 0.25rem
  border-radius: 344px
  background: #efeff1
  @media(min-width: $tablet)
    padding: 0.4rem 0.5rem



.filterChip
  font-weight: 600
  background: #efeff1 !important
  //margin-right: 15px !important

.filterChip:first-child
  @media(max-width: $tablet) and (min-width: $small-mobile)
    //margin-left: -6px !important
  @media(max-width: $small-mobile)
    //margin-bottom: 0.75rem !important


.filterChipSelected
  color: #fff !important
  background: $primary-color !important


.filterChipLabel
  padding-right: 16px !important
  padding-left: 12px !important
  font-size: 14px

.filterChipIcon
  color: $primary-color !important
  margin-left: 14px !important
  //margin: unset !important
  //border: 2px solid $primary-color
  //border-radius: 259px
  //padding: 0.35rem
  //background: #fff
.filterChipIconSelected
  color: #fff !important
  margin-left: 14px !important



.thanksModalPaper
  border-radius: 24px !important
  padding: 0.25rem 2rem 0.5rem !important


.shareArrowIconContainer
  background: #1d343c !important
  position: absolute !important
  bottom: -1.2rem !important
  right: 9.5rem !important
  border: 2px solid #fff !important
  @media(max-width: $tablet)
    padding: 10px !important
    right: 8.25rem !important


.shareArrowIconContainerNavbar
  border: 2px solid #fff !important
  background: #1d343c !important
  margin-right: 0.5rem !important

.shareArrowIconContainer:hover, .shareArrowIconContainerNavbar
  cursor: pointer

.shareArrowIcon
  //transform: scaleX(-1)
  width: 1.5rem
  height: 1.5rem
  fill: #ffffff
  color: #ffffff

.favIconHeaderContainer
  cursor: pointer
  background: #1d343c !important
  position: absolute !important
  bottom: -1.2rem !important
  right: 5.5rem !important
  border: 2px solid #fff !important
  @media(max-width: $tablet)
    padding: 10px !important
    right: 4.75rem !important

.favIconHeaderContainerNavbar
  cursor: pointer
  background: #1d343c !important
  border: 2px solid #fff !important
  margin-right: 0.5rem !important
  @media(max-width: $tablet)
    padding: 10px !important

.favIconHeader
  width: 4rem
  height: 4rem
  fill: #ff4757
  color: #ff4757
  @media(max-width: $tablet)
    width: 2.35rem
    height: 2.35rem


.remainingTimeWrapper
  position: fixed
  z-index: 1290
  top: 0.5rem
  right: 0.5rem
  @media(min-width: $tablet)
    top: 1rem
    right: 5%
    //right: 6rem

.gridPubliMobileItem
  //padding: 8px 16px 3px 16px !important
  //padding: 8px 0 3px 0 !important //v2

.mobileBannerIntersection
  aspect-ratio: 36 / 11
  width: 98%
  box-shadow: rgb(0 0 0) 12px 12px 1px 0
  //margin-left: -6px
  //width: calc(100% + 14px)

.desktopBannerIntersection
  aspect-ratio: 36 / 11
  max-width: 100%
  box-shadow: rgb(0 0 0) 12px 12px 1px 0



.rawBannerIntersectionItem
  //padding: 0 !important
  @media(max-width: $tablet)
    //padding: 10px 0 !important

.rawBannerIntersection
  box-shadow: rgb(0 0 0) 12px 12px 1px 0
  aspect-ratio: 36 / 11
  max-width: 100%
  @media(max-width: $tablet)
    width: 100%
    max-width: unset
  //width: calc(100% + 14px)
  //margin-left: -6px



.endBannersWrapperItem
  padding: 0 !important
  @media(min-width: $tablet)
    padding: 6px 0 2px !important


.adTopImg
  //aspect-ratio: 21 / 9
  aspect-ratio: 36 / 11
  width: 100%

.mainContentBox
  padding: 2rem


.gridEventItem:last-child
  justify-self: flex-start
  margin-right: auto


.optionsDrawer
  z-index: 1810 !important

.errorText
  padding: 15px
  text-align: center


.featuredEventsContainer::-webkit-scrollbar
  width: 0 !important

.featuredEventsContainer
  -ms-overflow-style: none
  scrollbar-width: none
  display: flex
  flex-direction: row
  overflow: scroll
  width: 100%
  margin-left: -1.25rem
  padding-bottom: 2rem
  padding-left: 0.5rem
  @media(max-width: $tablet)
    width: 108.75%
    margin-left: -4.05rem


.navbar
  display: flex
  position: sticky
  flex-wrap: wrap
  top: -1px
  margin-left: -1px
  padding: 0.75rem 16px
  z-index: 10
  transition: 0.25s ease-in-out



.entityAvatarSmall, .entityAvatar
  transition: 0.25s ease-in-out
  background: #ffffff !important
  object-fit: contain !important

.entityAvatar
  width: 5rem !important
  height: 5rem !important
  margin-top: -3.5rem
  margin-left: 1rem
  margin-right: 1rem
  @media(min-width: $tablet)
    margin-top: -4.5rem
    width: 8rem !important
    height: 8rem !important

.entityAvatarSmall
  width: 4rem !important
  height: 4rem !important
  //margin-top: -5.5rem
  margin-left: 1rem
  margin-right: 1rem
  @media(min-width: $tablet)
    width: 5rem
    height: 5rem

.filterOptionsDialog
  border-radius: 32px !important
  padding-top: 2rem
  padding-bottom: 1.5rem
