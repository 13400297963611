@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"

.container
  display: flex
  //justify-content: center
  flex-direction: column
  width: 100%
  @media (min-width: $big-tablet)
// padding: $paddingDesktopPage

.UploadVideo
  position: relative
  display: flex
  justify-content: center
  flex-direction: column
  overflow: hidden
  width: 90%

.titleVideoRequest
  font-weight: $regular-font-weight
  font-size: $medium-font-size
  text-align: center
  background-color: $blue-ligth-color
  border-left: 4px solid $blue-color
  padding: 8px 4px

.form
  padding: 20px 5px
  display: flex
  flex-direction: column
  user-select: none !important
  -webkit-tap-highlight-color: transparent

  .addIcon
    width: 100%
    height: 100px

    path
      -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .2))
      filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .2))

  .inputSelect
    margin-top: 16px
    margin-bottom: 16px

  .filepond
    color: #fff !important

    fieldset > div:first-child
      margin-top: 1rem !important
    fieldset > div
      background-color: $primary-color !important

      button
        background-color: $blue-color !important

    span, svg
      color: #fff !important

    label
      font-size: 1rem
      white-space: pre-line
      //width: 80%
      font-weight: 400

    label:hover
      cursor: pointer

.form:hover
  -webkit-tap-highlight-color: transparent
  cursor: pointer

.submitButton
  color: $white-color !important
  background-color: $blue-color !important
  text-transform: none !important
  border-radius: 200px !important
  padding: 5px 40px !important
  margin-top: 0.5rem !important
  margin-bottom: 0.5rem !important
  font-size: $large-font-size !important
  box-shadow: none !important
  @media (min-width: $tablet)
    margin-bottom: 1rem !important
.submitButton:disabled
  color: $white-color !important
  background-color: $material-grey-color !important

.progressBarContainer
  position: relative
  margin-bottom: 1rem

.progressBarMask
  background-color: white
  width: 100%
  height: 3rem
  position: absolute
  z-index: 5

.currentProgress
  background-color: $blue-color !important
  z-index: 4

.progressBar
  background-color: $blue-ligth-color !important
  min-height: 3rem !important
  border-radius: 75px !important
  z-index: 6

.progressBarPercent
  z-index: 6
  color: $primary-color
  position: absolute
  top: 50%
  left: 50%
  margin-top: -9px
  margin-left: -12px
  font-weight: bold

.isToRequestCheckbox, .buyableForAllCheckbox
  svg
    color: $blue-color

.disabledCheckbox
  svg
    color: #baa9bf !important

.buyableForAllClaim
  color: $blue-color

.helperModalContent
  padding: 0 !important
  @media (min-width: $tablet)
    padding: 1rem

.helperModalTitle
  padding-left: 1rem !important
  @media (min-width: $tablet)
    padding-left: 2rem !important

.helpersGrid
  justify-content: center
  @media (min-width: $tablet)
    padding: 1rem

.listItemHelper
  height: 14rem
  padding: 1rem
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-evenly

  img
    height: 70px !important
    width: 70px

.listIcon
  min-height: 5rem
  width: 100%
  display: flex
  justify-content: center
  align-items: center

.listText
  height: 4rem
  text-align: center
  font-size: $small-font-size
  @media (min-width: $tablet)
    font-size: $large-font-size

.textHelper
  font-size: $medium-font-size
  text-align: center
  font-weight: bold
  color: $blue-color !important
  text-decoration: underline
  margin: 0

.textHelper:hover
  color: $darkblue-color !important
  cursor: pointer

.arrowBack
  position: absolute !important
  top: 1em !important
  left: 1em !important
  cursor: pointer
  font-size: $title-font-size

.dialogRootFix
  //overflow-y: initial !important

.dialogContent
  display: flex
  //justify-content: center
  //align-items: center
  padding: 2rem !important
  border-radius: 42px !important
  @media (min-width: $tablet)
    padding: 2rem 4rem !important
    min-height: 28rem
    //align-items: center

.dialogMain
  //display: flex !important
  //flex-direction: column !important
  border-radius: 42px !important
  overflow-x: hidden !important

.dialogMobileFullScreen
  display: flex !important
  flex-direction: column !important
  border-radius: 0 !important
  @media (max-width: $tablet)
//background: rgba(255, 255, 255, 0.8) !important

.dialogBackdrop
  @media (max-width: $tablet)
    background: transparent !important

.dialogActions
  width: 100%
  margin-top: 2rem
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin-bottom: 1rem

  .actionsContainer
    width: 100%
    @media (min-width: $tablet)
      width: 85%

.closeModalIcon
  position: absolute !important
  padding: 1rem
  z-index: 10000 !important
  top: 2px
  right: 2px
  @media(min-width: $tablet)
    top: 0.5rem
    right: 1rem

  svg
    color: $primary-color

.uploadingVideoAlertDialogRoot
  z-index: 6000 !important

.uploadingVideoAlertTitle
  padding-top: 1.5rem
  padding-bottom: 1.15rem
  width: 100%
  font-size: 28px
  text-align: center
  color: $primary-color

.uploadingVideoAlertText
  line-height: 30px
  font-size: 20px
  width: 100%
  text-align: center
  color: $primary-color

.uploadingVideoAlertProgressContainer
  margin-top: 2rem

.uploadingVideoAlertProgressActions
  justify-content: center !important
  padding-bottom: 2.5rem !important

.uploadingVideoAlertProgressCTA
  background-color: $blue-color !important

.uploadingVideoAlertProgressCTA:disabled
  background-color: $material-grey-color !important

.codecErrorMessageBox
  background: rgba(255, 205, 205, 0.7)
  padding: 1rem
  margin-top: 1rem
  border-radius: 12px
  font-size: 13px
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px, rgba(0, 0, 0, 0.23) 0 3px 6px
  text-align: center

.mediaPreviewsContainer
  display: flex
  align-items: center
  background: #f1f0ef
  //background: #c9e3f4
  overflow-x: auto
  padding: 1rem
  border-radius: 8px

.infoLOPDContainer
  background-color: $grey-ligth-color
  //border-radius: 18px
  min-height: 3rem
  height: 3rem
  overflow: auto
  margin: 20px 0
  padding: 1rem 1.5rem
  font-size: $small-font-size

.termsCheck
  //margin-top: 0.5em
  display: flex
  flex-direction: column
  align-items: flex-start
.checkboxWrapper
  display: flex
  width: 100%
  //flex-direction: column
  align-items: center
.linkToTerms
  color: $primary-color
  text-decoration: none
  margin-left: 10px
  margin-top: 0

  a
    text-decoration: underline

.stepTitleTop
  user-select: none !important
  @media (min-width: $tablet)
    margin-top: 2rem
.stepTitle
  user-select: none !important
  margin-top: 7rem
  @media (min-width: $tablet)
    margin-top: 2rem

.stepSubtitle
  font-size: $large-font-size
  margin-bottom: 2rem

.nextStepButton
  color: $white-color !important
  background-color: $blue-color !important
  text-transform: none !important
  border-radius: 200px !important
  padding: 5px 40px !important
  margin-top: 0.5rem !important
  margin-bottom: 0.5rem !important
  font-size: $large-font-size !important
  box-shadow: none !important

.nextStepButton:disabled
  background-color: rgba(0, 0, 0, 0.12) !important
  color: #fff !important


.uploadingListItemText
  font-size: 1.4rem !important
  font-weight: bold !important
  line-height: 1.4rem !important
  margin-left: 0.5rem !important


.failedUploadsModalPaper
  border-radius: 24px !important
  padding: 0rem 1.5rem 0.5rem !important


.failedUploadsModalTitle
  font-size: 22px !important
  font-weight: 400 !important



.chooseUploadTypeDialogContent
  @media (max-width: $tablet)
    display: flex
    justify-content: center
    flex-direction: column

  @media (min-width: $tablet)
    padding: 4rem 2rem !important
    min-height: 28rem

.chooseUploadTypeParentContainer
  margin-top: 2rem
  margin-bottom: 2rem


.chooseUploadTypeRootBox
  border-radius: 24px
  border: 1px solid #000
  display: flex
  flex-direction: column
  align-items: center
  padding: 2rem 1rem

.chooseUploadTypeBoxTitle
  font-size: 20px
  text-align: center
  margin-bottom: 2rem

.chooseUploadTypeIconsBox
  display: flex


.textTypeIcon
  background: #e4ecfd !important
  width: 7rem !important
  height: 7rem !important
  color: #4550dc !important
  font-size: 3rem !important
  font-weight: bold !important
  font-family: Kiwi Maru, serif !important

