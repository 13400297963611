@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"

.dialogMain
  border-radius: 0 !important

.loginSubtitle
  margin-top: 3rem !important
  margin-bottom: 0 !important
  color: $primary-color !important
  max-width: 75%
  text-align: center
  font-size: $large-font-size !important
  font-weight: 300 !important
  @media (max-width: $mobile)
    max-width: 85%
.dialogRootFix
  overflow-y: initial !important
.dialogContent
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  border-radius: 0 !important
.form
  min-width: 70%
  @media (min-width: $tablet)
    width: 85%
.dialogActions
  width: 100%
  margin-top: 2rem
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin-bottom: 1rem
  .actionsContainer
    width: 100%
    @media (min-width: $tablet)
      width: 85%

.submitButton
  padding: 10px !important
  background-color: $blue-color !important
  color: $white-color !important
  border-radius: 50px !important
  cursor: pointer !important
  font-weight: $semi-bold-font-weight !important
  text-transform: none !important
  margin: -5px 0 !important

.submitButton:disabled
  background-color: rgba(0, 0, 0, 0.12) !important

.goToRegisterButton
  background-color: $primary-color !important
  margin-bottom: 15px !important

.progressCircle
  width: 25px !important
  height: 25px !important
  font-size: $micro-font-size !important
  position: absolute
  top: 50%
  left: 50%
  margin-top: -12px
  margin-left: -12px

.authVerifiedErrorMessage
  padding: 0.5em !important
  text-align: center !important
  color: red !important
  @media (min-width: $tablet)
    max-width: 65% !important

.closeModalIcon
  position: absolute !important
  top: 2px
  right: 2px

.footerTextContainer
  display: flex
  flex-direction: column
  font-weight: 300
  text-align: center
  margin-top: 2rem

.registerLinkLabel
  margin-top: 0.5rem

.newPasswordLink, .registerLink
  color: $blue-color !important
  margin-left: 5px
  cursor: pointer
  //text-shadow: $default-contrast-shadow
  text-decoration: underline

.dialogMobileFullScreen
  @media (max-width: $tablet)
    //background: rgba(255, 255, 255, 0.8) !important

.dialogBackdrop
  @media (max-width: $tablet)
    background: transparent !important


.socialLoginContainer
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  //margin-top: 2.5rem
  .socialLoginPlatformIconContainer
    width: 10%
    display: inline-flex
    .socialLoginPlatformIcon
      width: 1.45rem
      align-self: flex-start
      max-height: 2rem
  .socialLoginPlatformName
    color: $primary-color !important
    width: 80%
  .googleButton
    //background-color: #dd4b39 !important
    //color: #fff !important
    background-color: #fff !important
    color: $primary-color !important
    text-transform: none !important
    padding-top: 0.63rem !important
    padding-bottom: 0.63rem !important
    //border: 0.5px solid $primary-color
    border-radius: 75px !important

  .facebookButton
    text-transform: none !important
    margin-top: 1rem
    //background-color: #1877F2 !important
     //color: #fff !important
       background-color: #fff !important
    background-color: #ffff !important
    color: #1877F2 !important
    //border: 1px solid #1877F2 !important
    border-radius: 75px !important
    padding-top: 0.63rem !important
    padding-bottom: 0.63rem !important


  .googleButton:disabled, .facebookButton:disabled
    background-color: #b9b9b9 !important

.waitingSocialLoginProgress
  margin-left: 15px !important
  color: #000000 !important

.insetFormDivider
  margin-top: 2.5rem
  margin-bottom: 0.5rem
  width: 100%
  display: flex
  flex-direction: row
  justify-content: center
  .dividerSection
    width: 20%
    border-bottom: 1px solid #b3acac
    margin-bottom: 9px
  .dividerText
    width: 20%
    //margin: 0 1rem
    text-align: center
