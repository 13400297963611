@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"

.dialogMain
  border-radius: 24px !important
  padding: 0.5rem !important

.loginSubtitle
  margin-top: 2rem !important
  margin-bottom: 1rem !important
  color: $primary-color !important
  max-width: 75%
  text-align: center
  font-size: $large-font-size !important
  font-weight: $medium-font-weight !important
  @media (max-width: $mobile)
    max-width: 85%
.dialogRootFix
  overflow-y: initial !important
.dialogTitle
  font-size: 20px !important
  padding-bottom: 0 !important

.dialogContent
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  border-radius: 0 !important
  padding-bottom: 0.25rem !important
  //margin-top: 1rem
  //margin-bottom: 0.25rem

.closeModalIcon
  position: absolute !important
  top: 10px
  right: 10px
  color: #000 !important

.linkBox
  display: flex
  flex-direction: column
  align-items: flex-end
  margin-bottom: 1rem
  @media(min-width: $computer)
    width: 75%
    flex-direction: row
    align-items: center
    justify-content: center
.copyLinkButton
  color: $blue-color !important
  margin-left: 0.75rem !important
.socialIconsContainer
  display: flex
  justify-content: center
  margin-bottom: 1rem !important
  .socialIconBox
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center
    margin: 0.75rem
  .socialIcon
    border-radius: 355px !important
    padding: 1rem !important
    svg
      height: 1.75rem !important
      width: 1.75rem !important
  .socialIconName
    font-size: 12px
.copyIconContainer
  display: flex
  justify-content: center
  flex-direction: column
  margin-left: 2rem
  @media (max-width: $tablet)
    margin-top: 1rem
    margin-left: 0
.fileCopyIcon
  color: #ffffff
