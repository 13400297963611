@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"

$heightRow: 7rem
$widthRow: 28rem
$heightRowDesk: 10rem
$widthRowDesk: 40rem

@keyframes slide-up
  0%
    opacity: 0
    transform: translateY(20px)
    -moz-transform: translateY(20px)

  100%
    opacity: 1
    transform: translateY(0)
    -moz-transform: translateY(0)
    -o-transform: translateY(0)

@keyframes zoomIn
  0%
    opacity: 0
    transform: scale(0)
    -moz-transform: scale(0)
    -o-transform: scale(0)

  100%
    opacity: 1
    transform: scale(1)
    -moz-transform: scale(1)
    -o-transform: scale(1)


.backButton
  justify-self: flex-start
  align-self: flex-start
  background-color: #ffffff !important

.RequestFiles
  justify-content: center
  display: flex
  flex-direction: column
  @media (max-width: $tablet)
    padding-bottom: 5em
  @media (min-width: $big-tablet)
    padding: $paddingDesktopPage

  .titleContainer
    display: flex
    justify-content: center
    //margin-bottom: 1rem
    flex-direction: column
    padding: 1rem 32px
    @media (min-width: $big-tablet)
      padding: 0

    h1
      margin-bottom: 0
      font-size: $big-font-size
      @media (min-width: $big-tablet)
        font-size: $huge-font-size

    h3
      margin-top: 1rem
      font-size: $xl-font-size
      font-weight: $regular-font-weight
      @media (min-width: $big-tablet)
        font-size: $big-font-size

  .errorText
    padding: 15px

  .headerContainer
    padding: $paddingPage
    display: flex
    justify-content: flex-end
    position: relative
    height: $headerContainer
    @media (min-width: $big-tablet)
      display: none

    .locationContainer
      display: flex
      align-items: center

  .requestDataContainer
    padding: $paddingPage

  .firstInfoLineBox
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: space-between
    @media (min-width: $tablet)
      flex-direction: row
      align-items: center

    .infoAndActionWrapper
      display: flex
      flex-direction: row
      align-items: center
      margin-left: 1em

  .limitDateContainer
    display: flex
    align-items: center

  .tagsContainer
    display: flex

    .tag
      padding: 5px 10px
      margin: 5px
      border: 2px solid $blue-color
      color: $blue-color
      border-radius: 5px

  .videosMainContainer
    display: flex
    flex-direction: column
    align-items: center

  .videosContainer
    margin-top: 1rem
    @media (min-width: $big-tablet)
      padding: $videosGridContainerPadding
    .videoCard
      display: flex
      align-items: flex-start
      cursor: pointer
      width: 100%
      padding: 8px !important
      position: relative
      @media (max-width: $big-tablet)
        padding: 10px 28px !important
      .layerClickable
        position: absolute
        width: 98%
        height: 100%
        z-index: 20

      .layerClickable:hover
      //background-color: rgba(40, 40, 40, 0.1)
      .dataContainer
        width: 100%
        overflow: hidden
        text-align: justify
        max-height: $heightRow
        min-height: $heightRow
        @media (min-width: $big-tablet)
          min-height: $heightRowDesk
          max-height: $heightRowDesk

        .title
          font-weight: bold
          margin: 0
          font-size: $tiny-font-size
          @media (min-width: $big-tablet)
            font-size: $medium-font-size

        .tags
          display: flex
          flex-wrap: wrap
          font-size: $tiny-font-size
          text-align: initial
          color: $darkblue-color
          margin: 0
          margin-top: 10px
          overflow: hidden
          white-space: nowrap
          max-height: 3.9rem
          @media (min-width: $big-tablet)
            font-size: $small-font-size
            max-height: 6.2rem

          p
            color: $blue-color
            border: 1px solid $blue-color
            border-radius: 5px
            width: fit-content
            margin: 2px
            padding: 5px 10px

      .thumbnail
        margin-right: 1em
        overflow: hidden
        width: $widthRow
        height: $heightRow
        object-fit: cover
        border-radius: 10px
        @media (min-width: $big-tablet)
          height: $heightRowDesk
          width: $widthRowDesk

  .fixTabsFlex
    justify-content: center !important

  .tabsContainer
    border-bottom: 2px solid #efefef !important
    @media(min-width: $tablet)
  //padding-left: 10% !important
  //padding-right: 10% !important

  .tabColor
    color: #000000 !important

  .requestDescriptionContainer
    display: flex
    flex-direction: column
    align-items: center

    div:first-child
      width: 100%

    .requestTermsLink
      margin-top: 1rem
      margin-bottom: 1.25rem
      color: $blue-color !important

  .descriptionExpansorButton
    color: #797979 !important
    text-transform: none !important

.headImage
  max-width: 100%
  border-radius: 10px
  display: flex
  justify-content: center
  @media (min-width: $tablet)
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    height: 25em

  img
    max-width: 100%
    height: 38vh
    object-fit: cover !important
    @media (min-width: $tablet)
      display: none

.videoActionsButtons
  display: flex
  justify-content: center
  @media (min-width: $big-tablet)
    display: none

  .mainActionButton
    background-color: $blue-color !important
    color: #ffffff !important
    border-radius: 25px !important
    padding: 1em 3em !important
    position: fixed !important
    bottom: 2em
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important

.videoActionsButtonsDesktop
  margin-right: 3rem
  @media (max-width: $big-tablet)
    display: none

  .mainActionButtonDesktop
    background-color: $blue-color !important
    color: #ffffff !important
    border-radius: 25px !important
    min-width: 15rem
    padding: 1em 2em !important
    box-shadow: none !important


.videoGalleryContainer
  display: flex
  flex-wrap: wrap
  justify-content: space-around
  overflow: hidden

.videoGallery
  width: 100% !important
  margin-top: 0.2em !important
  padding: 0.5em !important
  max-height: 75vh
  @media (min-width: $tablet)
    padding: 1em !important

.uploadVideoTile
  animation: zoomIn 0.6s ease !important
  height: 100%
  color: #ffffff
  background-color: $blue-color
  display: flex !important
  flex-direction: column
  justify-content: center
  align-items: center !important
  text-align: center
  cursor: pointer

.uploadVideoText
  color: $white-color !important
  font-weight: $semi-bold-font-weight

.uploadVideoIcon
  color: $white-color !important
  font-size: 3.5em !important

.headerTile
  width: 100%
  box-shadow: 0 7px 5px 0 rgba(0, 0, 0, 0.25)
  z-index: 400

.videoGalleryHeader
  height: auto !important
  position: sticky !important

.videoGalleryHeader > div
  display: flex
  align-items: center

.videoTile > div
  animation: zoomIn 0.6s ease !important
  transition: ease-in-out 0.25s
  background-color: $primary-color
  display: flex
  justify-content: center
  align-items: center

.videoTile > div:hover
  cursor: pointer
  @media (min-width: $computer)
    //transform: scale(1.05)
    box-shadow: 0 0 8px 8px rgba(255, 255, 255, 0.86)

.tileSelectedOverlay
  z-index: 100
  display: flex
  justify-content: center
  align-items: center
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: transparentize($blue-color, 0.6)
  transition: ease-in-out 0.5s

  .checkIcon
    font-size: 6em !important
    opacity: 1 !important
    position: absolute !important
    color: $white-color !important

.fixClickOnTile
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%

.fixClickOnTile:hover
  cursor: pointer

.closeDrawerButton
  z-index: 1000 !important
  position: absolute !important
  top: 0
  right: 0

.uploadedVideoGallery
  width: 100% !important
  margin-top: 0.2em !important
  padding: 0.5em !important
  @media (min-width: $tablet)
    padding: 1em !important

.paginatorContainer
  display: flex
  justify-content: center
  padding: 0 !important
  padding-top: 1rem !important
  padding-bottom: 2rem !important

.sendVideoButton
  animation: slide-up 0.4s ease !important
  position: absolute !important
  right: 4em !important
  color: #ffffff !important
  background-color: $blue-color !important
  box-shadow: none !important
  border-radius: 50px !important

.sendIcon
  color: $white-color !important
  margin-left: 0.5em

.progressCircle
  color: $blue-color
  position: absolute
  top: 50%
  left: 50%
  margin-top: -12px !important
  margin-left: -12px !important

.thumbnail
  display: flex
  justify-content: center
  background-color: black
  height: 100%
  object-fit: cover

.shareButtonBox
  display: flex
  justify-content: center
  margin-top: 2rem

  .shareButton
    margin-left: 15px
    margin-right: 15px

.noVideosMessageContainer
  display: flex
  flex-direction: column
  align-items: center
  margin-left: -0.85rem

.noVideosImage
  width: 16rem

.csvActions
  display: flex
  justify-content: center
  flex-direction: column
  margin-bottom: 1rem
  padding: 0 28px
  @media (min-width: $tablet)
    padding: 0
    flex-direction: row
    justify-content: flex-start
.downloadAllFilesButton
  @media (max-width: $tablet)
    margin-bottom: 1rem

.downloadButton:first-child
  margin-right: 1rem
.downloadButton
  box-shadow: none !important
  background-color: $blue-ligth-color !important
  color: $blue-color !important
  margin-left: 16px
  border-radius: 355px !important
  text-transform: none !important
  font-weight: bold  !important
  padding: 0.85rem 2rem !important
  transition: all 0.1s ease-in-out !important
  @media (min-width: $tablet)
    margin-left: 0


.downloadButton:hover
  color: $blue-ligth-color !important
  background-color: $blue-color !important
  svg
    color: $blue-ligth-color !important

.skeletonContainer
  padding: 12px !important
  height: 20rem

  span > span
    height: 100%
    border-radius: 24px
