@import "~react-component-countdown-timer/lib/styles.css";

html, body, #app, #app > div, li, span, p, input, a, h2, h3, h4, h5, h6, textarea, legend, button, option, div {
    font-family: 'Montserrat', serif !important;
}

#app > li, p, input, a, h2, h3, h4, h5, h6, textarea, legend, option, div {
    color: #000000;
}

/* Temporal fix for UploadVideo Filepond */
.filepond--drop-label {
    background-color: #fff !important;
    border: 1px solid #000000 !important;
    color: #000000 !important;
    border-radius: 10px;
    /*box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);*/
}

.filepond--list {
    margin-top: 0.55rem !important;
}

/*.filepond--panel-root{
    background-color: transparent !important;
}*/
.filepond--drop-label:focus {
    /*background-color: #e5f5fd !important;*/
    background-color: #fff !important;
    border: 1px solid #000000 !important;
    color: #000000 !important;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.filepond--root .filepond--list-scroller {
    margin-top: 2rem !important;
}

input {
    -webkit-user-select: text; /* Chrome, Opera, Safari */
    -moz-user-select: text; /* Firefox 2+ */
    -ms-user-select: text; /* IE 10+ */
    user-select: text; /* Standard syntax */
}


/* ---------- VideoWall Caorusel styles ---------- */

.alice-carousel__next-btn-item, .alice-carousel__prev-btn-item {
    background: #ffffff;
    border-radius: 33rem;
    padding: 1rem 1.5rem !important;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.alice-carousel__prev-btn-item span, .alice-carousel__next-btn-item span { /*arrow icon*/
    color: #000000;
    font-size: 1.4rem;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
}

.alice-carousel__next-btn {

}

.alice-carousel__prev-btn {

}

.alice-carousel__next-btn-item__inactive, .alice-carousel__prev-btn-item__inactive {
    background: #303e43;
    border-radius: 4px;
    padding: 2rem;
}

.alice-carousel__prev-btn-item__inactive span, .alice-carousel__next-btn-item__inactive span { /*arrow icon*/
    color: #fff;
}


.alice-carousel__wrapper {
    /*padding-bottom: 2rem;*/
}

.alice-carousel {

}

.alice-carousel__dots {
    margin: 0 !important;
    margin-top: -2rem !important;
}

.alice-carousel__stage-item {
    padding: 0.6rem !important;
    transition: transform .2s; /* Animation */
}

.alice-carousel__stage-item.__target {

}

/* set rule for all, exclude first child */
.alice-carousel__stage-item.__active + .__active {
    /*transform: scale(1.2);*/

}

/* reset rule for all, exclude second child */
.alice-carousel__stage-item.__active ~ .__active ~ .__active {
    transform: none;

}

.alice-carousel__stage-item.__active {
}

/* ----------------------------------------------- */


/* -------------------- CONFIDENCE WALL STATS DATAGRID--------------------------- */

#data-grid-toolbar button {
    color: #00a3da !important;
}

#data-grid-toolbar button:nth-child(3) {
    display: none;
}

.MuiGridPanelFooter-root span {
    color: #00a3da !important;
}

.MuiDataGridColumnsPanel-column .MuiIconButton-label {
    color: #00a3da !important;
}

.MuiDataGrid-root .MuiDataGrid-colCell-draggable {
    justify-content: center !important;
    flex: unset !important;
}

.MuiDataGrid-root .MuiDataGrid-colCellTitleContainer {
    flex: unset !important;
}

.MuiDataGrid-root .MuiDataGrid-cell {
    text-align: center !important;
}

.MuiDataGrid-colCellTitle {
    font-weight: bold !important;
}

/* --------------------- CONCURSO COUNTDOWN TIMER-------------------------- */

#concurso-countdown-timer .countBox {
    display: flex;
    flex-direction: row-reverse;
}

#concurso-countdown-timer .countBoxItem {
    margin: 0.25rem;
    display: flex;
    flex-direction: column-reverse;
}

#concurso-countdown-timer .label {
    color: #ffff;
    font-size: 10px;
    font-family: system-ui !important;
}

#concurso-countdown-timer .count {
    background-color: #ffff !important;
    text-align: center;
    padding: 0.95rem 0.015rem;
    font-size: 24px;
    min-width: 2.75rem;
    border-radius: 4px;
    color: #000 !important;
    font-family: Arial, system-ui !important;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

#concurso-countdown-timer-2 {
    /*background: rgba(118, 206, 255, 0.2);*/
    /*border-radius: 16px;*/
    /*box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);*/
    /*backdrop-filter: blur(5px);*/
    /*-webkit-backdrop-filter: blur(5px);*/
    /*border: 1px solid rgba(118, 206, 255, 0.3);*/
}

#concurso-countdown-timer-2 .countBox {
    display: flex;
    flex-direction: row-reverse;

    /*background: rgba(118, 206, 255, 0.2);*/
    background: rgba(0, 0, 0, 0.69);
    border-radius: 12px;
    box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(5px);
    padding: 0.25rem;
    border: 1px solid rgba(255, 255, 255, 0.3);
}

#concurso-countdown-timer-2 .countBoxItem {
    margin: 0.25rem;
    display: flex;
    flex-direction: column-reverse;
}

#concurso-countdown-timer-2 .label {
    color: #ffff;
    text-align: center;
    font-size: 10px !important;
    font-family: Arial, system-ui !important;
}

#concurso-countdown-timer-2 .count {
    background-color: transparent !important;
    text-align: center;
    padding: 0.5rem 0.35rem;
    font-size: 24px;
    min-width: 2.25rem;
    border: none !important;
    border-radius: 9px;
    color: #fff !important;
    font-family: Arial, system-ui !important;
}

@media(max-width: 768px){
    #concurso-countdown-timer-2 .countBoxItem {
        margin: 0.10rem;
    }

    #concurso-countdown-timer-2 .label {
        font-size: 8px !important;
    }

    #concurso-countdown-timer-2 .count {
        padding: 0.15rem 0.25rem;
        font-size: 10px;
        min-width: 1.8rem;
    }
}

.vp-center{
    align-items: flex-start !important;
}


/* ---- GOOGLE TRANSLATE ----*/
#google_translate_element{
    background: #06232c;
}

.skiptranslate{
    visibility: hidden !important;
    background: #06232c !important;
    position: absolute !important;
    top: -5rem !important;
}
#goog-gt-{
    display: none !important;
}
body {
    top: 0px !important;
}
/*----------------------*/

/* ----------------------------------------------- */

#google_translate_element{
    background: #06232c
}
.skiptranslate{
     visibility: hidden!important;
     background: #06232c!important;
     position: absolute!important;
     top: -5rem!important
 }
#goog-gt-{
    display: none!important
}
