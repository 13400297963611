@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"


//Animations
@keyframes slide-in-left
  0%
    opacity: 0
    transform: translateX(-20px)

  100%
    opacity: 1
    transform: translateX(0)

@keyframes slide-up
  0%
    opacity: 0
    transform: translateY(20px)

  100%
    opacity: 1
    transform: translateY(0)
////////////////////////////////////////////////

.dialogHidden
  display: none !important
.dialogRoot
  z-index: 1280 !important
.dialog
  @media (min-width: $tablet)
    max-width: 900px !important
    border-radius: 32px !important
.linearProgress
  border-top-left-radius: 16px !important
  height: 0.35rem !important
  background-color: $material-grey-color !important
.linearProgressBar
  background-color: $darkblue-color !important

.dialogHeader
  display: flex
  align-items: center
  height: 3rem
  justify-content: flex-end
  padding: 1rem
  margin-top: -5px
  @media (max-width: $tablet)
    padding: 1rem 0
    height: 1rem
  // padding-top: 1rem
  //padding-right: 1rem

  .closeModalIcon
    justify-self: flex-end
    background-color: #fff
    padding: 0.25rem
    margin-top: 0.5rem
    margin-right: 0.5rem
    svg
      font-size: 34px !important
      color: $primary-color

.DetailVideo
  //margin-top: 1rem
  //padding-bottom: 2em !important
  @media (max-width: $tablet)
    padding-bottom: 6rem !important

  .floatingButtonContainer
    animation: slide-up 0.4s ease !important
    position: fixed
    bottom: 15px
    width: 50%
    right: 25%
    left: 25%
    @media (min-width: $tablet)
      display: none

    .floatingButton
      font-weight: $semi-bold-font-weight !important
      font-size: $medium-font-size !important
      background-color: $blue-color
      color: $white-color
      width: 100%
      @media (max-width: 435px)
        font-size: 0.8rem !important

    .actionButtonIcon
      color: #fff !important
      margin-right: 0.5em !important

  .errorText
    padding: 15px

  .headerContainer
    position: relative
    justify-content: flex-end
    padding: $paddingPage
    display: flex
    height: $headerContainer
    @media (min-width: $big-tablet)
      display: none

    .arrowBack
      cursor: pointer
      font-size: $title-font-size

    .locationContainer
      display: flex
      align-items: center


  .bodyContainer
    margin-top: 0.5rem
    padding-left: 25px
    padding-right: 25px
    margin-bottom: 0.5rem
    @media(min-width: $tablet)
      margin-top: 15px


    .videoUserName
      font-size: $large-font-size
      text-align: left
      margin-top: 5px
      //color: #686767
      color: $primary-color
      @media(min-width: $tablet)
        margin-bottom: 0
        font-size: $large-font-size


    .deleteIcon
      font-size: $large-font-size

  .titleContainer
    position: relative
    display: flex
    //justify-content: center
    align-items: start
    //margin-top: 1rem
    flex-direction: column
    @media(min-width: $tablet)
      margin-top: 0.5rem

    .videoTitle
      width: 75% !important
      text-align: left
      //font-size: $large-font-size
      font-size: $xl-font-size
      font-weight: bold
      @media (max-width: $tablet)
        margin-top: 0.75rem
      @media (min-width: $tablet)
        font-size: $big-font-size
        font-weight: 500
        text-align: left

  .actionsBox
    //position: absolute
    //right: 4rem
    //top: 0
    position: relative
    display: flex
    justify-content: start
    align-items: flex-end
    @media (max-width: $tablet)
      right: 0.15rem
      position: relative

  //.tagsContainer
    display: flex
    justify-content: center
    flex-wrap: wrap

    .tag
      padding: 5px 10px
      margin: 5px 5px 5px 0
      border: 1.2px solid $blue-color
      color: $blue-color !important
      border-radius: 5px

  .categoriesContainer
    display: flex
    flex-wrap: wrap
    justify-content: center

    .category
      padding: 0 5px 10px 0
      margin: 5px 5px 5px 0
      color: $darkblue-color !important
      text-decoration: underline
      cursor: pointer

  .tagsSectionTitle
    font-size: $small-font-size
    font-weight: bold !important

  .vimeoWrapper
    width: 100%
    position: relative
    @media (max-width: $tablet)
      max-height: 27rem !important

  .vimeoSpinner
    color: $darkblue-color !important
    position: absolute
    top: 50%
    left: 50%
    margin-top: -35px
    margin-left: -30px
    //transform: translate(-50%, -50%)

  .vimeoHidden
    height: 0 !important
    opacity: 0 !important
  .iframeStyle
    transition: opacity 0.25s, height 0.25s
    iframe
      background-color: #000
      @media (max-width: $tablet)
        max-height: 27rem !important
      @media (min-width: $computer)
        width: 100%
      body
        align-items: flex-start !important

.downloadsModalContent
  display: flex
  flex-direction: column
  @media (min-width: $tablet)
    padding: 7rem 4rem !important
    flex-direction: row
    justify-content: center

.downloadClaim
  font-weight: bold
  margin-left: 1.5rem !important

.qualityDownloadButton
  min-height: 2rem
  animation: slide-in-left ease 0.4s !important
  margin: 0 0.5em !important
  border-color: #00a3da !important
  text-transform: none !important
  @media (max-width: $tablet)
    margin: 1em !important

  a
    text-decoration: none

  .downloadIcon
    color: $blue-color !important
    margin-left: 0.5em


.freeDownloadOrPurchaseContent
  @media (max-width: $tablet)
    display: flex
    justify-content: space-evenly
    flex-direction: column

.freeDownloadOrPurchaseText
  color: $darkblue-color !important
  margin-bottom: 2em !important

.purchaseConfirmationActionsContainer
  display: flex
  justify-content: space-evenly !important
  padding-bottom: 0.8em !important
  flex-wrap: wrap
  @media (max-width: $tablet)
    flex-direction: column !important
    align-items: center !important

  .freeDownloadButton
    background-color: transparent !important
    border: 1px solid $blue-color !important
    border-radius: 75px !important
    color: $blue-color !important
    min-width: 17em !important
    box-shadow: none

    svg
      color: $blue-color
      margin-right: 0.5em

  .buyVideoButton
    background-color: $blue-color !important
    color: #ffffff !important
    min-width: 17em !important
    box-shadow: none
    @media (max-width: $tablet)
      margin-top: 1em

    svg
      color: #ffffff
      margin-right: 0.5em

.shareButtonBox
  display: flex
  justify-content: center
  margin-top: 1rem

  .shareButton
    border-radius: 3rem !important
    margin-left: 15px
    margin-right: 15px
    font-size: 18px

    svg
      margin-right: 0.25rem



.desktopActionButton
  height: 2.5rem
  width: 13rem !important
  box-shadow: none
  border-radius: 3em !important
  padding-left: 1.5em !important
  padding-right: 1.5em !important
  margin-right: 1em !important
  background-color: $blue-color !important
  color: #fff !important
  @media (max-width: $tablet)
    display: none

  .actionButtonIconDesktop
    //color: $blue-color !important
    color: #fff !important
    margin-right: 0.5em !important


.priceChip
  font-size: 1.15rem !important
  margin-right: 1.15rem
  margin-left: auto
  color: $primary-color
  @media(min-width: $tablet)
    margin-right: 0.5rem

  .euroIcon
    font-size: 1.15rem
    color: $primary-color

  .euroIcon:hover
    cursor: initial
    color: initial

  .priceChip:hover
    cursor: initial

  .priceChip:active
    background-color: initial

.desktopDownloadButton
  svg
    color: $blue-color !important

.favIcon, .downloadIconSkeleton
  svg
    color: $blue-color !important

.skeletonContainer
  width: 100%
  display: flex
  justify-content: center
  align-items: center

.closeModalDownloadIcon
  position: absolute !important
  right: 0 !important

.actionButton:hover, .actionButtonFilled:hover
  background-color: transparent !important
.actionButton, .actionButtonFilled
  padding-top: 22px !important
  padding-bottom: 22px !important
  color: $primary-color !important
  //border: 1px solid $white-color !important
  //margin-left: 0.5rem !important
  //margin-right: 0.5rem !important
  //padding: 8px !important
  svg
    color: $primary-color
    //height: 24px
    //width: 24px
    //fill: $primary-color
.actionButtonFilled
  //background: $blue-ligth-color !important
  svg
    //height: 24px
    //width: 24px

.actionButtonLabelWrapper
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  min-width: 40px
.actionButtonLabel
  //margin-top: 4px
  font-size: 12px !important
  color: #000000
  font-weight: 500


.tagsContainer
  display: flex !important
  justify-content: center !important
  flex-wrap: wrap
  margin-top: 1.25rem !important

  .tagAvatar
    background-color: $blue-color !important

  .tagChip
    margin: 0.25rem 0.45rem
    color: $blue-color !important
    border-color: $blue-color !important

.mainImageWrapper
  position: relative
  width: 100%
  //object-fit: contain
  display: flex
  justify-content: center
  align-items: center
  background-color: #000000
  @media(max-width: $tablet)
    min-height: unset


.mainImageLazyWrapper
  padding: 1rem

.mainImage
  position: relative
  max-width: 100%
  width: 100%
  @media(max-width: $tablet)
    max-height: 45vh
    width: unset
    max-width: 100%

.mainImageFullscreen
  max-width: 100%
  max-height: 100%
  object-fit: contain
  position: relative

.fullscreenImageToggleButton
  background: #fff
  position: absolute !important
  bottom: 0
  right: 0

.modalNavigationContainer
  display: flex
  justify-content: center
  align-items: center
.desktopNavigationButton
  background: #ffff
  z-index: 4000
.desktopNavigationIconLeft, .desktopNavigationIconRight
  width: 4.5rem
  height: 4.5rem

.mobileNavigationIconLeft, .mobileNavigationIconRight
  width: 2.5rem
  height: 2.5rem


.mobileNavigationButtonLeft
  z-index: 4000
  background: #fff
  position: fixed !important
  bottom: 0.5rem
  left: 10px


.mobileNavigationButtonRight
  z-index: 4000
  background: #fff
  position: fixed !important
  bottom: 0.5rem
  right: 10px
  //@media (min-width: $tablet)
  //  top: 50%
  //  right: 11%
  //  bottom: unset


.desktopNavigationIconRight, .mobileNavigationIconRight
  transform: rotate(180deg)


.adTopContainer
  display: flex
  justify-content: center
  //margin-top: -3.05rem
  //margin-top: -2.5rem
  //margin-bottom: 0.4rem
  @media (min-width: $tablet)
    //margin-top: -3.05rem
    //margin-bottom: 0.4rem
    margin-top: 0

.adTopImg
  aspect-ratio: 36 / 11
  width: 100%
  @media (min-width: $tablet)
    aspect-ratio: 33/10
    //max-width: 31rem

.adMobilePopupDialogRoot
  background-color: #000000a8
  box-shadow: none !important
  display: flex
  justify-content: center
  border: 0 !important

.adMobilePopupDialogPaper
  position: revert !important
  overflow-y: hidden !important
  overflow-x: hidden !important
  background-color: transparent !important
  box-shadow: none !important
  border-radius: 0 !important



.adMobilePopupImageWrapper
  text-align: center
  background-color: transparent
  position: relative
  width: fit-content


.closeAdModalIcon
  //border: 2px solid #1a1818 !important
  position: absolute !important
  top: 4px
  right: 5px
  background-color: #fff !important
  padding: 0.25rem !important
  svg
    font-size: 34px !important
    color: $primary-color


.desktopActionsWrapper
  padding: 1rem 1.25rem 1rem 0.75rem
  display: flex
  align-items: center
  @media (max-width: $tablet)
    margin-left: -0.5rem
    padding: unset

.vimeoDesktopWrapper
  position: relative
  width: 100%

.actionButtonCounter
  font-weight: 400
  min-width: 10px

.navigationBox
  margin-left: auto
  @media (min-width: $tablet)
    //margin-right: 1rem

.closeModalButton
  z-index: 200
  display: none !important
  position: absolute !important
  justify-self: flex-end
  background-color: #fff !important
  padding: 6px !important
  right: 0.5rem !important
  top: 0.5rem !important
  svg
    //font-size: 65px !important
    font-size: 32px !important
    color: $primary-color
  @media (min-width: $tablet)
    display: block !important
    top: 1rem !important
    left: 1rem !important
    right: unset !important

.navigationTopBar
  display: flex
  justify-content: center
  align-items: center
  background-color: #ffffff
  position: sticky
  top: 0
  z-index: 11

.navigationTopBarTitle
  font-size: 22px
  color: #000
  //margin-left: auto
  margin-right: auto
  white-space: nowrap
  text-overflow: fade
  font-weight: 500

.descriptionContainer::-webkit-scrollbar
  width: 0 !important

.descriptionContainer
  -ms-overflow-style: none
  scrollbar-width: none
  margin-top: 0.5rem
  @media (min-width: $tablet)
    line-height: 24px
    max-height: 23.05rem
    overflow: auto
  @media (max-width: $tablet)
    margin-top: -0.5rem
