@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"

.Profile
  display: flex
  flex-direction: column
  overflow-y: hidden
  padding: 0.5rem 0
  @media (max-width: $mobile)
    margin-bottom: 5rem
  @media (min-width: $big-tablet)
    padding: $paddingDesktopPage !important

  .title
    margin-bottom: 0

  .content
    @media (max-width: $big-tablet)
      padding: 0 28px
  .contactUs
    font-weight: $regular-font-weight

    .linkContact
      text-decoration: none
      margin-left: 0.2em
      color: $blue-color !important
      cursor: pointer

    .linkContact:hover
      text-decoration: underline

  .panel:last-child
    border-radius: 8px !important

  .panel::before
    content: none

  .panel
    margin: 1.25rem 0 !important
    box-shadow: none
    border: 0.5px solid black
    border-radius: 8px

    .expandIcon
      height: 36px
      width: auto

    .expansionSummary
      padding: 0 10px 0 20px !important

    .expansionSummary > div
      margin: 0 !important

    .titleExpansionSummary
      font-weight: $regular-font-weight
      font-size: $large-font-size

    .panelDetails
      display: flex
      flex-direction: column

      p
        font-weight: $regular-font-weight
        font-size: $medium-font-size

  .title
    text-align: center

  .list
    //width: 85%
    z-index: 22
    @media (min-width: $big-tablet)
      width: 30%
      min-width: 500px

    .iconInfoButton
      position: absolute
      margin-left: -45px

      .iconInfo
        color: darkgray
        font-size: x-large

    .iconShare
      margin-left: 5px
      color: $blue-color

    .listItemText
      margin-left: -5px !important

      span
        font-weight: $regular-font-weight
        font-size: $large-font-size

    .listItemTextTabbed
      margin-top: -15px !important

      .validateButton
        margin-top: 15px
        background-color: #00a3da
        color: #fff
        text-transform: none
        font-size: 0.675rem
        box-shadow: none

      .validateButtonDisabled
        background-color: $complementary-silver-color

    .emailDomain
      margin-left: -5px !important
      font-weight: $light-font-weight
      font-size: $medium-font-size
      color: $complementary-darkgrey-color

    .innerList
      width: 85%

      .listItemTextMini
        margin-left: -5px !important

        span
          font-weight: $regular-font-weight
          font-size: $medium-font-size

    .littleIcon
      font-size: $big-font-size

    svg
      font-size: $title-font-size
      color: $primary-color

.rateUsListItem
  display: flex !important
  justify-content: center !important

.rateUsContainer
  display: flex

.rateUsLogoBox
  text-align: center

  span
    font-size: $medium-font-size !important


.titleContainer
  display: flex
  justify-content: center
  margin-bottom: 1rem
  flex-direction: column
  @media (min-width: $big-tablet)
    padding: 0

  h1
    margin-bottom: 0
    font-size: $big-font-size
    @media (min-width: $big-tablet)
      font-size: $huge-font-size

  h3
    margin-top: 1rem
    font-size: $xl-font-size
    font-weight: $regular-font-weight
    @media (min-width: $big-tablet)
      font-size: $big-font-size

.profileIllustration
  position: absolute
  bottom: 6%
  right: 10%
  width: 29%
  @media (max-width: $big-tablet)
    display: none
