@import "../../../styles/colors"
@import "../../../styles/typography"
@import "../../../styles/responsive"
@import "../../../styles/sizes"

.ideas
  margin-top: -18vh
  overflow: hidden
  @media (min-width: $big-tablet)
    padding: $paddingDesktopPage !important
  .circularProgress
    position: absolute
    top: 35%
    left: 45%
    @media (min-width: $big-tablet)
      left: 60%
  .iframeStyle
    width: 100%
    height: 118vh
    border: none