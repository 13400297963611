$primary-color: #000000
$white-color: #fff
$dark-white-color: #f6f6f6
$material-grey-color: #BDBDBD
$material-grey-dark-color: rgba(52, 49, 76, 0.54)
$grey-ligth-color: #eeeeee
$blue-color: #00a3da
$blue-color-hover: #0198ca
$darkblue-color: #364550
$blue-ligth-color: #cfeeff
$blue-ligth-color2: #c4e6ff
$blue-ligth-color-hover: #a4edff
$blue-ligth-color-hover-2: #cfeeff
$red-color: #ff4757



$box-background: #f7f7f7



$complementary-darkgrey-color: #3E3F3D
$complementary-charcoal-color: #121c29
$complementary-concrete-color: #716b77
$complementary-silver-color: #dddcdc
$complementary-soft-color: #f5f5f5
$complementary-sand-color: #fafafa
$complementary-white-color: #ffffff
$complementary-black-opacity-7: rgba(0, 0, 0, 0.7)

$error-color: #ff3d00

$snack-error: #dd2c01
$snack-success: #4fb457
$snack-warning: #ffac00

$facebook-pantone-color: #3b5998
$twitter-pantone-color: #55acee
$linkedin-pantone-color: #0077b5
$instagram-pantone-color: #8a3ab9
$youtube-pantone-color: #ff0000
