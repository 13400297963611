@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"


$heightRow: 7rem
$widthRow: 28rem
$heightRowDesk: 10rem
$widthRowDesk: 40rem

.listVideos
  display: flex
  flex-direction: column
  align-items: center
  overflow-x: hidden
  overflow: hidden
  @media (min-width: $big-tablet)
    padding: $paddingDesktopPage !important
  @media (max-width: $tablet)
    padding-bottom: 5rem !important
  .paginatorContainer
    display: flex
    justify-content: center
    padding: 0 !important
    padding-top: 1rem !important
    padding-bottom: 2rem !important
  .skeletonContainer
    padding: 12px !important
    height: 13rem
    span
      border-radius: 8px !important

  .titleContainer
    position: relative
    display: flex
    width: 100%
    align-items: center
    justify-content: center
    min-height: $headerContainer
    flex-direction: column
    @media (min-width: $big-tablet)
      flex-direction: row
      width: 98%
      justify-content: space-between
    .backButton
      position: absolute !important
      top: 1rem
      left: 0
      .arrowBack
        cursor: pointer !important
        font-size: $title-font-size !important
        color: #000
        @media (min-width: $big-tablet)
          display: none !important
    .titleCategory
      font-size: $big-font-size
    .top10Text
      @media (max-width: $tablet)
        margin-top: 0
    .orderContainer
      display: flex
      align-items: center
      justify-content: space-around
      width: 70%
      margin-top: 1rem
      margin-bottom: 1rem
      @media (min-width: $big-tablet)
        margin-top: 0
        margin-bottom: 0
      .selectParentContainer
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        width: 50%
        @media (min-width: $big-tablet)
          flex-direction: row
        label
          color: #9e9e9e
      .selectParentContainer:first-child
        margin-right: 1rem
        @media (min-width: $big-tablet)
          margin-right: 0.25rem
      .selectContainer
        width: 100%
        z-index: 50
        margin: 0.5rem
    .searchText
      font-style: italic
      position: absolute
      left: 0
      top: 65%
      color: $darkblue-color
      font-size: $tiny-font-size
      @media (min-width: $big-tablet)
        font-size: $small-font-size
  .videosContainer
    padding: $paddingPage
    margin-top: 0.25rem
    overflow: hidden
    @media (max-width: $tablet)
      padding: $paddingListVideos
    .videoCard
      display: flex
      align-items: flex-start
      cursor: pointer
      width: 100%
      padding: 12px
      position: relative
      @media (max-width: $big-tablet)
        padding: 10px !important
      .layerClickable
        position: absolute
        width: 98%
        height: 100%
        z-index: 20
      .layerClickable:hover
        //background-color: rgba(40, 40, 40, 0.1)
      .dataContainer
        width: 100%
        overflow: hidden
        text-align: justify
        max-height: $heightRow
        min-height: $heightRow
        @media (min-width: $big-tablet)
          min-height: $heightRowDesk
          max-height: $heightRowDesk
        .title
          font-weight: bold
          margin: 0
          font-size: $tiny-font-size
          @media (min-width: $big-tablet)
            font-size: $medium-font-size
        .tags
          display: flex
          flex-wrap: wrap
          font-size: $tiny-font-size
          text-align: initial
          color: $darkblue-color
          margin: 0
          margin-top: 10px
          overflow: hidden
          white-space: nowrap
          max-height: 3.9rem
          @media (min-width: $big-tablet)
            font-size: $small-font-size
            max-height: 6.2rem
          p
            color: $blue-color
            border: 1px solid $blue-color
            border-radius: 5px
            width: fit-content
            margin: 2px
            padding: 5px 10px
      .thumbnail
        margin-right: 1em
        overflow: hidden
        width: $widthRow
        height: $heightRow
        object-fit: cover
        border-radius: 10px
        @media (min-width: $big-tablet)
          height: $heightRowDesk
          width: $widthRowDesk
.freeTag
  background-color: $blue-color
  color: rgb(255, 255, 255)
  padding: 0.25rem 0.75rem !important
  border-radius: 25rem
  position: absolute
  bottom: 10%
  margin-left: 0.25rem
  font-size: 0.75rem
  box-shadow: 1px 2px 12px -1px rgba(0, 0, 0, 0.75)
.freeBadge
  span
    font-size: 1rem !important
    padding: 0.75rem !important
    background-color: $blue-color !important