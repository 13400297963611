@import "../../../styles/colors"
@import "../../../styles/typography"
@import "../../../styles/responsive"
@import "../../../styles/sizes"

.gridItemCenteredContent:first-child
  @media(max-width: $tablet)
    padding-top: 0

.gridItemCenteredContent
  display: flex
  justify-content: center
  align-items: center
  padding: 10px 26px


.addIcon
  margin-right: 0.5em
  width: 2rem
  height: auto

.myDownloads
  display: flex
  flex-direction: column
  justify-content: center
  @media (min-width: $big-tablet)
    padding: $paddingDesktopPage !important

  .titleContainer
    display: flex
    justify-content: center
    margin-bottom: 1rem
    flex-direction: column
    padding: 1rem 32px
    @media (min-width: $big-tablet)
      padding: 0
    h1
      margin-bottom: 0
      font-size: $big-font-size
      @media (min-width: $big-tablet)
        font-size: $huge-font-size
    h3
      margin-top: 1rem
      font-size: $xl-font-size
      font-weight: $regular-font-weight
      @media (min-width: $big-tablet)
        font-size: $big-font-size

  .gridContainer
    //padding: 0.5rem 16px
    justify-content: center
    @media (min-width: $tablet)
      padding: 0

  .floatingButton
    position: fixed
    bottom: 10px
    right: 10px

  .videoCard
    width: 100%
    position: relative
    border-radius: 8px !important
    @media (max-width: $big-tablet)
      padding: 10px 28px !important
    .videoCover
      height: 13rem
      @media (max-width: $tablet)
        height: 11rem
    .videoCardHeader
      padding: 28px 10px 10px 0
      span:first-child, span:nth-child(2)
        text-align: center

    .videoCardContent
      .videoCardContentText
        span
          font-weight: $medium-font-weight !important

        p
          font-weight: $regular-font-weight !important

    .videoCardActions
      display: flex
      justify-content: center
      flex-direction: column
      align-items: center
      margin-bottom: 2rem

    .showRequestDetailButton
      background-color: $blue-color !important
      border-radius: 25px !important
      color: #ffffff !important
      width: 50% !important
      box-shadow: none
      padding: 10px
      @media (min-width: $big-tablet)
        position: absolute
        bottom: 3vh

    .deleteButton
      border-radius: 25px !important
      width: 50% !important
      margin: 10px

    .deleteIconButton
      position: absolute
      top: 0
      right: 0

      svg
        color: red !important

.skeletonBox
  //height: 65vh
  min-height: 12rem
  max-height: 13rem
  border-radius: 10px
  align-items: center
  justify-content: center
  //margin: 1em !important
  span>span
    height: 100%
    border-radius: 24px

.videoRow
  transition: ease-in-out 0.15s

.videoRow:hover
  background-color: $blue-ligth-color-hover
  cursor: pointer

.videoThumbnailCellContainer
  height: 10rem

  .videoThumbnail
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.15), 0 0 6px rgba(0, 0, 0, 0.13)
    width: 100%
    height: 100%
    object-fit: cover
    border-radius: 12px
    object-position: center

  .videoThumbnail:hover
    cursor: pointer

.tagsContainer
  display: flex
  justify-content: center
  flex-wrap: wrap
  .tag
    padding: 5px 10px
    margin: 5px 5px 5px 0
    border: 1.2px solid $blue-color
    color: $blue-color !important
    border-radius: 5px

.downloadButtonMobile
  position: absolute !important
  bottom: 8px !important
  right: 8px !important
  color: $blue-color !important
  background-color: #ffffff !important
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)
  svg
    color: $blue-color !important

.mobileVideoInfo
  position: absolute
  width: 100%
  bottom: 0
  background-color: rgba(16, 33, 46, 0.61)
  span, p
    color: #ffffff !important

.actionsContainer
  display: flex
  flex-direction: column
  align-items: flex-end
.paginatorContainer
  display: flex
  justify-content: center
  padding: 0 !important
  padding-top: 1rem !important
  padding-bottom: 2rem !important
.skeletonContainer
  padding: 10px 26px
  height: 20rem
  span>span
    height: 100%
    border-radius: 24px
