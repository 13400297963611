@import "../../../styles/colors"
@import "../../../styles/typography"
@import "../../../styles/responsive"
@import "../../../styles/sizes"

$heightRow: 7rem
$widthRow: 28rem
$heightRowDesk: 10rem
$widthRowDesk: 40rem

.favourites
  display: flex
  flex-direction: column
  overflow: hidden
  @media (min-width: $big-tablet)
    padding: $paddingDesktopPage !important
  @media (max-width: $tablet)
    padding-bottom: 5rem !important
  .skeletonContainer
    height: 20rem
    @media (max-width: $tablet)
      padding: 10px 26px
    span>span
      height: 100%
      border-radius: 24px
    .backButton
      position: absolute !important
      left: 0
      .arrowBack
        cursor: pointer !important
        font-size: $title-font-size !important
        color: #000
        @media (min-width: $big-tablet)
          display: none !important
    .titleCategory
      font-size: $big-font-size
      @media (max-width: $big-tablet)
        margin-bottom: 0 !important
    .top10Text
      @media (max-width: $tablet)
        margin-top: 0
    .orderContainer
      display: flex
      align-items: center
      justify-content: space-around
      width: 70%
      margin-top: 1rem
      margin-bottom: 1rem
      @media (min-width: $big-tablet)
        margin-top: 0
        margin-bottom: 0
      .selectParentContainer
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        width: 50%
        @media (min-width: $big-tablet)
          flex-direction: row
        label
          color: #9e9e9e
      .selectParentContainer:first-child
        margin-right: 1rem
        @media (min-width: $big-tablet)
          margin-right: 0.25rem
      .selectContainer
        width: 100%
        z-index: 50
        margin: 0.5rem
    .searchText
      font-style: italic
      position: absolute
      left: 0
      top: 65%
      color: $darkblue-color
      font-size: $tiny-font-size
      @media (min-width: $big-tablet)
        font-size: $small-font-size
  .videosContainer
    //padding: $paddingPage !important
    //margin-top: 0.25rem
    //overflow: hidden
    .videoCard
      display: flex
      align-items: flex-start
      cursor: pointer
      width: 100%
      //padding: 12px
      position: relative
      @media (max-width: $big-tablet)
        padding: 10px 28px !important
      .layerClickable
        position: absolute
        width: 98%
        height: 100%
        z-index: 20
      .layerClickable:hover
      //background-color: rgba(40, 40, 40, 0.1)
      .dataContainer
        width: 100%
        overflow: hidden
        text-align: justify
        max-height: $heightRow
        min-height: $heightRow
        @media (min-width: $big-tablet)
          min-height: $heightRowDesk
          max-height: $heightRowDesk
        .title
          font-weight: bold
          margin: 0
          font-size: $tiny-font-size
          @media (min-width: $big-tablet)
            font-size: $medium-font-size
        .tags
          display: flex
          flex-wrap: wrap
          font-size: $tiny-font-size
          text-align: initial
          color: $darkblue-color
          margin: 0
          margin-top: 10px
          overflow: hidden
          white-space: nowrap
          max-height: 3.9rem
          @media (min-width: $big-tablet)
            font-size: $small-font-size
            max-height: 6.2rem
          p
            color: $blue-color
            border: 1px solid $blue-color
            border-radius: 5px
            width: fit-content
            margin: 2px
            padding: 5px 10px
      .thumbnail
        margin-right: 1em
        overflow: hidden
        width: $widthRow
        height: $heightRow
        object-fit: cover
        border-radius: 10px
        @media (min-width: $big-tablet)
          height: $heightRowDesk
          width: $widthRowDesk
.freeTag
  background-color: $blue-color
  color: rgb(255, 255, 255)
  padding: 0.25rem 0.75rem !important
  border-radius: 25rem
  position: absolute
  bottom: 10%
  margin-left: 0.25rem
  font-size: 0.75rem
  box-shadow: 1px 2px 12px -1px rgba(0, 0, 0, 0.75)

.paginatorContainer
  display: flex
  justify-content: center
  padding: 0 !important
  padding-top: 1rem !important
  padding-bottom: 2rem !important


.titleContainer
  display: flex
  justify-content: center
  margin-bottom: 1rem
  flex-direction: column
  padding: 1rem 32px
  @media (min-width: $big-tablet)
    padding: 0
  h1
    margin-bottom: 0
    font-size: $big-font-size
    @media (min-width: $big-tablet)
      font-size: $huge-font-size
  h3
    margin-top: 1rem
    font-size: $xl-font-size
    font-weight: $regular-font-weight
    @media (min-width: $big-tablet)
      font-size: $big-font-size

.noVideos
  display: flex
  justify-content: center
.downloadAllFilesButton

.actions
  margin-bottom: 3rem
  @media (max-width: $big-tablet)
    margin-bottom: 2rem
    padding: 0 28px

