@import "../../../styles/colors"
@import "../../../styles/typography"
@import "../../../styles/responsive"
@import "../../../styles/sizes"

.rootContainer
  padding: 1rem
  text-align: center
  @media (min-width: $tablet)
    padding: 2rem 4rem

  .legalContent
    margin-top: 2rem
    padding: 1rem
    //background: #efefef
    border-radius: 12px

