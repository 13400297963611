@import "../../../styles/colors"
@import "../../../styles/typography"
@import "../../../styles/responsive"
@import "../../../styles/sizes"

.gridItemCenteredContent:first-child
  padding-top: 0

.gridItemCenteredContent
  display: flex
  justify-content: center
  align-items: center
  padding: 12px 12px
  @media (min-width: $big-tablet)
    padding: 16px 0


.addIcon
  margin-right: 0.5em
  width: 2rem
  height: auto

.myRequests
  //margin-top: $navbarHeight
  display: flex
  flex-direction: column
  justify-content: center
  @media (min-width: $big-tablet)
    padding: $paddingDesktopPage !important


  .gridContainer
    padding: 0.5rem 16px
    //justify-content: center
    @media (min-width: $tablet)
      padding: 0 !important

  .addRequestContainer
    display: flex
    justify-content: center

  .addRequestButton
    font-weight: $regular-font-weight
    font-size: $large-font-size
    text-transform: none

  .floatingButton
    position: fixed
    bottom: 10px
    right: 10px

  .eventCard:hover
    cursor: pointer
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important

  .eventCard
    width: 100%
    height: 100%
    position: relative
    border: 1px solid $material-grey-color
    border-radius: 24px !important
    box-shadow: none
    transition: box-shadow 0.3s ease-in-out
    .eventCardActionArea:hover
      background: initial !important

    .eventCardImage
      height: 14rem


    .requestCardHeader
      padding: 28px 10px 10px 0

      span:first-child, span:nth-child(2)
        text-align: center

    .requestCardContent
      padding: 8px !important

      .requestCardContentText
        span
          font-weight: $medium-font-weight !important

        p
          font-weight: $regular-font-weight !important

    .eventCardTitle
      //overflow: hidden
      //white-space: nowrap
      //text-overflow: ellipsis
      display: -webkit-box !important
      -webkit-line-clamp: 2 !important
      /* autoprefixer: ignore next */
      -webkit-box-orient: vertical !important
      overflow: hidden
      font-size: $large-font-size !important

    .requestCardActions
      display: flex
      justify-content: center
      flex-direction: column
      align-items: center
      margin-bottom: 2rem

    .showRequestDetailButton
      background-color: $blue-color !important
      border-radius: 25px !important
      color: #ffffff !important
      width: 50% !important
      box-shadow: none
      padding: 10px
      @media (min-width: $big-tablet)
        position: absolute
        bottom: 3vh

    .deleteButton
      border-radius: 25px !important
      width: 50% !important
      margin: 10px

    .deleteIconButton
      position: absolute
      top: 0
      right: 0

      svg
        color: red !important

.skeletonBox
  //min-height: 65vh
  align-items: center
  justify-content: center
  padding: 10px 16px

  span > span
    height: 100%
    border-radius: 24px

.requestRow
  transition: ease-in-out 0.25s

.requestRow:hover
  background-color: $dark-white-color
  cursor: pointer

.requestThumbnailCellContainer
  height: 10rem

  .requestThumbnail
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.15), 0 0 6px rgba(0, 0, 0, 0.13)
    width: 100%
    //height: 100%
    //object-fit: cover
    border-radius: 12px
    object-position: center

  .requestThumbnail:hover
    cursor: pointer

//.actionsContainer
//  display: flex
//  justify-content: center
//  align-items: center
//  flex-direction: column


.actionsContainer
  display: flex
  align-items: center
  flex-direction: column
  justify-content: center !important
  //margin-top: 1rem
  padding: 24px !important
  padding-bottom: 1.5rem !important


  .videosButton, .contestButton
    margin-left: 0 !important
    width: 100% !important
    background-color: $blue-ligth-color
    color: $blue-color
    //border: solid $blue-color 2px
    border-radius: 50px
    text-transform: none !important
    font-size: $large-font-size !important

  .videosButton:hover, .contestButton:hover
    background-color: $blue-color
    color: $blue-ligth-color

  .contestButton
    margin-top: 1rem


  .mobileVideosCountRoot
    width: 65%

  .mobileVideosCountBadge
    background-color: $blue-color
    color: $white-color
