@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"

.button
  //width: 100%
  box-shadow: none !important
  background-color: $blue-ligth-color !important
  color: $blue-color !important
  border-radius: 355px !important
  text-transform: none !important
  font-weight: bold  !important
  padding: 0.85rem 2rem !important
  transition: all 0.1s ease-in-out !important
  @media (max-width: $tablet)
    width: 100%
.button:hover
  color: $blue-ligth-color !important
  background-color: $blue-color !important
  svg
    color: $blue-ligth-color !important


//DIALOG STYLES
.dialog
  padding: 1rem 2rem
  border-radius: 24px !important
.dialogContent
  @media (max-width: $tablet)
    padding: 0 !important

.closeModalIcon
  position: absolute !important
  top: 2px
  right: 2px


.dialogCtaLabel
  color: $blue-color !important
  display: flex !important
  font-weight: 400 !important
  @media (max-width: $tablet)
    align-items: center !important
    flex-direction: column-reverse !important

