@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"


//Animations
@keyframes slide-in-left
  0%
    opacity: 0
    transform: translateX(-20px)

  100%
    opacity: 1
    transform: translateX(0)

@keyframes slide-up
  0%
    opacity: 0
    transform: translateY(20px)

  100%
    opacity: 1
    transform: translateY(0)
////////////////////////////////////////////////

.container
  height: 100vh
  display: flex
  justify-content: center
  align-items: center
.content
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  .accountOk
    text-align: center
  .actionButton
    background-color: $blue-color !important
    color: #ffffff !important
    margin-top: 1em !important
  .circularProgress
    margin-top: 1em