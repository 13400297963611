@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"

.progressBarContainer
  position: relative
  margin-bottom: 1rem
  .progressBarMask
    background-color: white
    width: 100%
    height: 3rem
    position: absolute
    z-index: 5
  .currentProgress
    background-color: #0088bc !important
    z-index: 4
  .progressBar
    background-color: $darkblue-color !important
    min-height: 3rem !important
    border-radius: 75px !important
    z-index: 6
  .progressBarPercent
    z-index: 6
    color: #ffffff
    position: absolute
    top: 50%
    left: 50%
    margin-top: -9px
    margin-left: -12px
    font-weight: bold