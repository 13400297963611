@import "../../../../styles/colors"
@import "../../../../styles/typography"
@import "../../../../styles/responsive"
@import "../../../../styles/sizes"
//Animations
@keyframes slide-in-left
  0%
    opacity: 0
    transform: translateX(-20px)

  100%
    opacity: 1
    transform: translateX(0)

@keyframes slide-up
  0%
    opacity: 0
    transform: translateY(20px)

  100%
    opacity: 1
    transform: translateY(0)
////////////////////////////////////////////////

.dialogHidden
  display: none !important

.topScrollPaper
    align-items: flex-start !important

.topPaperScrollBody
    vertical-align: top !important
    //vertical-align: unset !important
    @media(max-width: $tablet)
      height: fit-content !important
    @media(min-width: $tablet)
      margin-top: 3.25rem !important

.centeredPaperScrollBody
  vertical-align: unset !important


.dialog
  @media(min-width: $tablet)
    //padding: 3rem !important
    border-radius: 48px !important
    overflow: hidden
.dialogRoot
  @media(max-width: $tablet)
    background: #f0f8ff87 !important
.dialogBackdrop
  overflow: hidden
  //background-color: rgba(0, 0, 0, 0.8) !important
  background-color: transparent !important



.linearProgress
  border-top-left-radius: 16px !important
  height: 0.35rem !important
  background-color: $material-grey-color !important
.linearProgressBar
  background-color: $darkblue-color !important

.dialogHeader
  display: flex
  align-items: center
  height: 3rem
  justify-content: flex-end
  padding: 1rem
  margin-top: -5px
  @media (max-width: $tablet)
    padding: 1rem 0
    height: 1rem

  .closeModalIcon
    justify-self: flex-end
    background-color: #fff
    padding: 0.25rem
    margin-top: 0.5rem
    margin-right: 0.5rem
    svg
      font-size: 34px !important
      color: $primary-color


.closeModalIconMobile
  position: absolute !important
  right: 5px
  top: 5px
  z-index: 1333 !important
  background-color: #ffffff !important
  border: 2px solid #000000 !important
  padding: 4px !important
  svg
    color: $primary-color
    width: 1.35em
    height: 1.35em

.floatingButtonContainer
  animation: slide-up 0.4s ease !important
  position: fixed
  bottom: 15px
  width: 50%
  right: 25%
  left: 25%
  @media (min-width: $tablet)
    display: none

.floatingButton
  font-weight: $semi-bold-font-weight !important
  font-size: $medium-font-size !important
  background-color: $blue-color
  color: $white-color
  width: 100%
  @media (max-width: 435px)
    font-size: 0.8rem !important

.actionButtonIcon
  color: #fff !important
  margin-right: 0.5em !important

.errorText
  padding: 15px

.headerContainer
  position: relative
  justify-content: flex-end
  padding: $paddingPage
  display: flex
  height: $headerContainer
  @media (min-width: $big-tablet)
    display: none

.arrowBack
  cursor: pointer
  font-size: $title-font-size

.locationContainer
  display: flex
  align-items: center


.videoActions
  position: absolute
  right: 4rem
  top: 0
  display: flex
  justify-content: center
  align-items: center
  min-height: 3rem
  @media (max-width: $tablet)
    right: 0.15rem
    position: relative
    margin-top: 10px


.vimeoHidden
  height: 0 !important
  opacity: 0 !important


.navigationGridItemLeft, .navigationGridItemRight
  display: flex
  align-items: center

.navigationGridItemLeft
  justify-content: flex-end

.navigationGridItemRight
  justify-content: flex-start

.navigationButtonRight
  svg
    transform: rotate(180deg)

.navigationButtonLeft, .navigationButtonRight
  z-index: 7000 !important

  svg
    height: 4rem
    width: 4rem
    fill: $primary-color
    @media(min-width: $tablet)
      height: 5.5rem
      width: 5.5rem


.mobileNavigationIconLeft, .mobileNavigationIconRight
  width: 2.5rem
  height: 2.5rem


.mobileNavigationButtonLeft
  z-index: 4000
  background: #fff
  position: fixed !important
  bottom: 0.5rem
  left: 0


.mobileNavigationButtonRight
  z-index: 4000
  background: #fff
  position: fixed !important
  bottom: 0.5rem
  right: 0

  svg
    transform: rotate(180deg)


.navigationIcon
  font-size: 25px !important
  color: $primary-color !important
  @media (min-width: $tablet)
    font-size: 35px !important

.errorMessageContainer
  background-color: #ffffff
  border-radius: 8px
  padding: 2rem
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column

  .animationWrapper
    margin-bottom: 1rem



.mainActionButtonMobile
  width: 100% !important
  color: $white-color !important
  background-color: #000000 !important
  text-transform: none !important
  border-radius: 8px !important
  padding: 8px 25px !important
  margin-top: 0.5rem !important
  margin-bottom: 0.5rem !important
  font-size: $medium-font-size !important
  z-index: 1290 !important
  transition: 0.5s
  //animation: slide-up 0.4s ease !important
  //position: fixed !important
  bottom: 0.7rem
  font-weight: 400 !important
  box-shadow: none !important

.mainActionButton
  color: $white-color !important
  //background-color: $blue-color !important
  background-color: #000000 !important
  text-transform: none !important
  border-radius: 200px !important
  padding: 5px 25px !important
  margin-top: 0.5rem !important
  margin-bottom: 0.5rem !important
  font-size: $medium-font-size !important
  z-index: 1290 !important
  transition: 0.5s
  //animation: slide-up 0.4s ease !important
  position: fixed !important
  bottom: 0.7rem
  font-weight: 400 !important
  //box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important
  box-shadow: none !important
  //font-weight: 300
  @media(max-width: $tablet)
    padding: 5px 20px !important
    font-size: $small-font-size !important
    height: 42px !important
    width: 100%

  @media(min-width: $tablet)
    right: 2.75rem
    position: absolute !important
    bottom: 1.5rem


.DetailVideo
  @media (max-width: $tablet)
    //box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)
    padding-bottom: 1rem
    background: #fff

  @media (min-width: $tablet)
    overflow: hidden
    border-radius: 20px

  .floatingButtonContainer
    animation: slide-up 0.4s ease !important
    position: fixed
    bottom: 15px
    width: 50%
    right: 25%
    left: 25%
    @media (min-width: $tablet)
      display: none

    .floatingButton
      font-weight: $semi-bold-font-weight !important
      font-size: $medium-font-size !important
      background-color: $blue-color
      color: $white-color
      width: 100%
      @media (max-width: 435px)
        font-size: 0.8rem !important

    .actionButtonIcon
      color: #fff !important
      margin-right: 0.5em !important

  .errorText
    padding: 15px

  .headerContainer
    position: relative
    justify-content: flex-end
    padding: $paddingPage
    display: flex
    height: $headerContainer
    @media (min-width: $big-tablet)
      display: none

    .arrowBack
      cursor: pointer
      font-size: $title-font-size

    .locationContainer
      display: flex
      align-items: center


  .bodyContainer
    margin-top: 1rem
    padding-left: 25px
    padding-right: 25px
    margin-bottom: 1rem
    @media(min-width: $tablet)
      margin-top: 15px


    .videoUserName
      font-size: $tiny-font-size
      text-align: left
      margin-top: 5px
      //color: #686767
      color: $primary-color
      @media(min-width: $tablet)
        margin-bottom: 0
        font-size: $large-font-size


    .deleteIcon
      font-size: $large-font-size

  .titleContainer
    position: relative
    display: flex
    //justify-content: center
    align-items: start
    //margin-top: 1rem
    flex-direction: column
    @media(min-width: $tablet)
      margin-top: 0.5rem

    .videoTitle
      text-align: left
      //font-size: $large-font-size
      font-size: $medium-font-size
      font-weight: bold
      @media (max-width: $tablet)
        margin-top: 0.75rem
      @media (min-width: $tablet)
        font-size: $big-font-size
        font-weight: 500
        text-align: left

  .actionsBox
    position: relative
    display: flex
    justify-content: start
    align-items: flex-end
    @media (max-width: $tablet)
      right: 0.15rem
      position: relative

  .vimeoWrapper
    width: 100%
    position: relative
    @media (max-width: $tablet)
      max-height: 27rem !important
  .vimeoSpinner
    color: $darkblue-color !important
    position: absolute
    top: 50%
    left: 50%
    margin-top: -35px
    margin-left: -30px
  //transform: translate(-50%, -50%)

  .vimeoHidden
    height: 0 !important
    opacity: 0 !important
  .iframeStyle
    transition: opacity 0.25s, height 0.25s
    iframe
      background-color: #000
      @media (max-width: $tablet)
        max-height: 27rem !important
      @media (min-width: $computer)
        width: 100%
      body
        align-items: flex-start !important


.shareButtonBox
  display: flex
  justify-content: center
  margin-top: 1rem

  .shareButton
    border-radius: 3rem !important
    margin-left: 15px
    margin-right: 15px
    font-size: 18px

    svg
      margin-right: 0.25rem


.desktopActionButton
  height: 2.5rem
  width: 13rem !important
  box-shadow: none
  border-radius: 3em !important
  padding-left: 1.5em !important
  padding-right: 1.5em !important
  margin-right: 1em !important
  background-color: $blue-color !important
  color: #fff !important
  @media (max-width: $tablet)
    display: none

  .actionButtonIconDesktop
    color: #fff !important
    margin-right: 0.5em !important


.desktopDownloadButton
  svg
    color: $blue-color !important

.favIcon, .downloadIconSkeleton
  svg
    color: $blue-color !important

.skeletonContainer
  width: 100%
  display: flex
  justify-content: center
  align-items: center

.closeModalDownloadIcon
  position: absolute !important
  right: 0 !important

.actionButton:hover, .actionButtonFilled:hover
  background-color: transparent !important
.actionButton, .actionButtonFilled
  padding-top: 22px !important
  padding-bottom: 22px !important
  color: $primary-color !important
  svg
    color: $primary-color

.actionButtonLabelWrapper
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  min-width: 40px
.actionButtonLabel
  //margin-top: 4px
  font-size: 12px !important
  color: #000000
  font-weight: 500


.mainImageWrapper
  position: relative
  width: 100%
  //object-fit: contain
  display: flex
  justify-content: center
  align-items: center
  background-color: #000000
  @media(max-width: $tablet)
    min-height: unset


.mainImage
  position: relative
  max-width: 100%
  width: 100%
  @media(max-width: $tablet)
    max-height: 50vh
    width: unset
    max-width: 100%


.modalNavigationContainer
  display: flex
  justify-content: center
  align-items: center
.desktopNavigationButton
  background: #ffff
  z-index: 4000
.desktopNavigationIconLeft, .desktopNavigationIconRight
  width: 4.5rem
  height: 4.5rem

.mobileNavigationIconLeft, .mobileNavigationIconRight
  width: 2.5rem
  height: 2.5rem


.mobileNavigationButtonLeft
  z-index: 4000
  background: #fff
  position: fixed !important
  bottom: 0.5rem
  left: 10px


.mobileNavigationButtonRight
  z-index: 4000
  background: #fff
  position: fixed !important
  bottom: 0.5rem
  right: 10px

.desktopNavigationIconRight, .mobileNavigationIconRight
  transform: rotate(180deg)

.actionsWrapper
  justify-content: space-between
  display: flex
  align-items: center
  @media (min-width: $tablet)
    padding: 1rem 1.25rem 1rem 1.25rem

.vimeoDesktopWrapper
  position: relative
  width: 100%

.actionButtonCounter
  font-weight: 400
  min-width: 10px


.closeModalButton
  z-index: 200
  display: none !important
  position: absolute !important
  justify-self: flex-end
  background-color: #fff !important
  padding: 6px !important
  right: 0.5rem !important
  top: 0.5rem !important
  svg
    //font-size: 65px !important
    font-size: 32px !important
    color: $primary-color
  @media (min-width: $tablet)
    display: block !important
    top: 1rem !important
    left: 1rem !important
    right: unset !important

.navigationTopBar
  padding: 0.5rem
  display: flex
  justify-content: flex-end
  align-items: center
  background-color: #ffffff
  position: sticky
  top: 0
  z-index: 11

.navigationTopBarTitle
  font-size: 22px
  color: #000
  //margin-left: auto
  margin-right: auto
  white-space: nowrap
  text-overflow: fade
  font-weight: 500

.descriptionContainer::-webkit-scrollbar
  width: 0 !important

.descriptionContainer
  -ms-overflow-style: none
  scrollbar-width: none
  margin-top: 0.5rem
  @media (min-width: $tablet)
    line-height: 24px
    max-height: 23.05rem
    overflow: auto
  @media (max-width: $tablet)
    margin-top: -0.5rem
