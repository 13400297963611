$navbarHeight: 5px
$paddingPage: 0 16px
$videosGridContainerPadding: 0.5rem 8px
$paddingListVideos: 0 35px
$paddingDesktopPage: 1.25rem 3vh 1rem 35.25vh
$paddingConcursoPage: 0 17% 1rem 17%
$paddingConcursoPageWithAds: 0 1.25rem 1rem 1.25rem
$videowallIframePadding: 1.25rem 17% 1rem 17%
$leftDrawerFixPadding: 25%
$headerContainer: 4rem
