@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"


.desktopDrawer>div
  padding-top: 5em !important
  min-width: 32vh
  max-width: 32vh
  //box-shadow: 0 5px 20px rgba(0,0,0,0.1), 0 5px 20px rgba(0,0,0,0.1)
  //border-right: none
  background-color: $white-color
  .list
    padding-left: 2px
    padding-right: 1.25rem
    //padding-top: 3rem
    .separator
      border-width: 0.5px !important
      color: #d1d1d133 !important
      border-color: #d1d1d133 !important
    svg
      font-size: $title-font-size
      color: $primary-color
    span
      font-weight: $regular-font-weight
      font-size: $medium-font-size

.listItem
  margin-top: 0.25rem !important
  margin-bottom: 0.25rem !important
  border-top-right-radius: 22rem !important
  border-bottom-right-radius: 22rem !important
.listItem:hover
  background: $blue-ligth-color !important

.loginButton:hover
  background: $blue-ligth-color !important

.loginButton
  span, svg
    color: $blue-color !important

.itemSelected
  background: $blue-ligth-color !important
  border-top-right-radius: 22rem !important
  border-bottom-right-radius: 22rem !important
  span, svg
    color: $blue-color !important
    font-weight: bold !important