@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"

.parentContainer
  display: flex
  flex-direction: column
  padding: $paddingPage
  @media (max-width: $mobile)
    margin-bottom: 5rem
  @media (min-width: $big-tablet)
    padding: $paddingDesktopPage !important
  .title
    margin-bottom: 1rem !important
  .panel:last-child
    border-radius: 8px !important
  .panel
    margin: 5px 0
    box-shadow: none
    border: 1px solid black
    border-radius: 8px
    width: 100%
    @media (min-width: $big-tablet)
      width: 60%
    .expandIcon
      height: 36px
      width: auto
    .panelSummary
      padding: 0 10px 0 20px !important
    .panelSummary>div
      margin: 0 !important
      .panelSummaryText
        font-size: $medium-font-size
        font-weight: $regular-font-weight
  .panelDetails
    padding-top: 0 !important
    margin-top: -1rem
  .panelDetailsTypography
    font-size: $small-font-size !important
    font-weight: $regular-font-weight


.titleContainer
  display: flex
  justify-content: center
  margin-bottom: 1rem
  flex-direction: column
  padding: 1rem 32px
  @media (min-width: $big-tablet)
    padding: 0
  h1
    margin-bottom: 0
    font-size: $big-font-size
    @media (min-width: $big-tablet)
      font-size: $huge-font-size
  h3
    margin-top: 1rem
    font-size: $xl-font-size
    font-weight: $regular-font-weight
    @media (min-width: $big-tablet)
      font-size: $big-font-size

.faqIllustration
  position: absolute
  top: 25%
  right: 0
  width: 33%
  @media (max-width: $big-tablet)
    display: none