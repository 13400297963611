@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"

@keyframes ripple
  0%
    transform: scale(0, 0)
    opacity: 1

  20%
    transform: scale(25, 25)
    opacity: 1

  100%
    opacity: 0
    transform: scale(40, 40)

.Navbar
  color: white
  background-color: #10212e
  align-items: center
  position: sticky
  top: 0
  display: flex
  justify-content: space-between
  flex-direction: row
  //width: 100vw
  min-height: 3.8rem
  z-index: 1210
  box-shadow: 0 7px 4px 0 rgba(0, 0, 0, 0.25)
  @media (max-width: $tablet)
    flex-direction: column
    justify-content: center
    align-items: center

  .logo
    color: $white-color
    width: 9.5rem
    height: auto
    margin-left: 1rem
    cursor: pointer
    object-fit: contain
    align-self: flex-start
    //margin-top: -0.25rem
    @media (min-width: $big-tablet)
      align-self: initial
      margin-left: 1.75rem


  .menuButton, .closeDrawerButton
    @media (max-width: $tablet)
      position: absolute
      top: 0
      right: 0
      margin-right: 0
    .menuIcon
      font-size: $title-font-size
    svg
      color: $white-color !important
//.anchorRightDrawerEntity
//  height: calc(100% - 6rem) !important
//  bottom: 0 !important
//  top: initial !important
//.anchorRightDrawerUser
//  height: calc(100% - 3.5rem) !important
//  bottom: 0 !important
//  top: initial !important
//.drawer > div:first-of-type
//  display: none
.drawer
  top: initial !important
  height: 1rem !important
  z-index: 1200 !important
  .drawerUserContent
      margin: 20% 0
      width: 100vw
  .drawerEntityContent
    //margin-top: 35%
    margin-top: 4rem
    //width: 100vw
    width: 99.5vw
    @media (min-width: $tablet)
      margin-top: 20%
  .list
    padding-left: 2px
    padding-right: 2rem
    .separator
      border-width: 0.5px
    svg
      font-size: $title-font-size
      color: $primary-color
    span
      font-weight: $regular-font-weight
      font-size: $large-font-size

.actionButtonsContainer
  display: flex
  justify-content: center
  align-items: center
  margin-right: 2em
  @media (max-width: $tablet)
    display: none !important
  .goToRequestsButton
    height: 2.5rem
    padding: 0 2rem
    color: $blue-color !important
    border-radius: 200px !important
    margin: 0 0.5em !important
    border-color: $blue-color !important
  .uploadVideoButton
    border: none
    color: #ffffff !important
    position: relative
    height: 2.5rem
    min-width: 14em !important
    background-color: $blue-color !important
    border-radius: 200px !important
    margin-left: 0.7em !important
  .uploadVideoButtonMobile
    border: none
    color: #ffffff !important
    position: relative
    height: 2.5rem
    min-width: 14em !important
    background-color: $blue-color !important
    border-radius: 200px !important
    margin-left: 0.7em !important
  .profileIconButton
    background-color: #ffffff !important
    padding: 6px !important
    margin-left: 0.7em !important
    .profileIcon
      color: #000




.listItem
  margin-top: 0.25rem !important
  margin-bottom: 0.25rem !important
  border-top-right-radius: 22rem !important
  border-bottom-right-radius: 22rem !important
.listItem:hover
  background: $blue-ligth-color !important

.loginButton:hover
  background: $blue-ligth-color !important

.loginButton
  span, svg
    color: $blue-color !important

.itemSelected
  background: $blue-ligth-color !important
  border-top-right-radius: 22rem !important
  border-bottom-right-radius: 22rem !important
  span, svg
    color: $blue-color !important
    font-weight: bold !important
