@import "../../../styles/colors"
@import "../../../styles/typography"
@import "../../../styles/responsive"
@import "../../../styles/sizes"

.NewRequest
  padding: $paddingPage
  display: flex
  flex-direction: column
  align-items: center
  @media (min-width: $big-tablet)
    padding: $paddingDesktopPage !important
  .titleContainer
    display: flex
    justify-content: center
    width: 100%
    align-items: center
  .subtitle
    margin-bottom: 0
    text-align: center
    font-weight: $regular-font-weight
    font-size: $large-font-size
  .form
    margin: 0 5px 20px 5px
    .addIcon
      width: 100%
      height: 100px
    .inputSelect
      margin: 15px 0
    .filepond
      .filepond--root
        font-size: $large-font-size
      .filepond--panel-root
        color: #555
  .submitButton
    color: $white-color
    background-color: $blue-color
    border-radius: 200px
    padding: 10px
    margin: 15px 0
    box-shadow: none
.circularProgress
  position: absolute
  top: 50%
  left: 50%
  margin-top: -12px
  margin-left: -12px

