@import "../../../styles/colors"
@import "../../../styles/typography"
@import "../../../styles/responsive"
@import "../../../styles/sizes"
//
//.gridContainer
//  margin-bottom: 5em !important
//  justify-content: center
//  @media (min-width: $big-tablet)
//    padding: $paddingDesktopPage
//  .sectionTitle
//    text-align: center
//  .requestsContainer
//    justify-content: flex-start !important
//    .requestParentBox
//      min-height: 20rem
//      display: flex
//      align-items: self-start
//      justify-content: center
//      background-repeat: no-repeat
//      background-size: cover
//    .requestParentBox:last-child
//      @media (max-width: $tablet)
//        margin-bottom: 5em
//    .requestCard
//      border-radius: 8px
//      padding: 0em 1em
//      margin-bottom: 3rem
//      display: flex
//      justify-content: center
//      align-items: center
//      flex-direction: column
//      min-width: 90%
//      max-width: 90%
//      position: relative
//      min-height: 15rem
//      border-radius: 0 !important
//      box-shadow: none !important
//      @media(min-width: $computer)
//        padding: 1em 1em
//      .requestImg
//        border-radius: 8px
//        height: 20rem
//      .requestCardContent
//        position: absolute
//        top: 1.5em
//        width: 100%
//        display: flex
//        flex-direction: column
//        justify-content: space-between
//        height: 8rem
//        padding-left: 0 !important
//        padding-right: 0 !important
//        @media (min-width: $computer)
//          height: 7rem
//
//      .firstLineInfoBox
//        display: flex
//        align-items: center
//      .limitDate
//        display: flex
//        justify-content: center
//        align-items: center
//      .limitDateTextContainer
//        display: flex
//        flex-direction: column
//        .limitDateSecondaryText
//          color: rgba(0, 0, 0, 0.54)
//          text-align: right
//      .title
//        //font-size: $large-font-size
//        line-height: initial !important
//        font-weight: bolder
//        margin-right: 0.5rem
//        max-width: 50% !important
//
//      .description
//        text-align: justify
//      .requestActionButtonBox
//        display: flex
//        justify-content: center
//        width: 70%
//        position: absolute
//        bottom: 1em
//        @media (min-width: $computer)
//          bottom: 2em
//
//        .actionButton
//          font-weight: $semi-bold-font-weight !important
//          font-size: $medium-font-size !important
//          background-color: $blue-color
//          color: $white-color
//          box-shadow: none !important
//          border-radius: 50px
//          height: 48px
//          @media (max-width: $mobile)
//            background-color: $darkblue-color
//
//.noRequestsContainer>p
//    text-align: center
//    width: 100%
//.skeletonBox
//  min-height: 20rem
//  align-items: center
//  justify-content: center
//  margin: 1em
//  margin-bottom: 2em
//
//.requestCardContainer
//  padding: 0 !important
//  margin-top: 1rem
//  max-height: 5rem
//  display: flex
//  flex-direction: row
//  justify-content: space-between
//  align-items: flex-start
//  width: 100%
//  //margin-bottom: 1rem




.gridItemCenteredContent:first-child
  @media (max-width: $tablet)
    padding-top: 0
.gridItemCenteredContent
  display: flex
  justify-content: center
  align-items: center
  padding: 16px 0
  @media (min-width: $tablet)
    padding: 12px 16px

.addIcon
  margin-right: 0.5em
  width: 2rem
  height: auto
.requestsRoot
  display: flex
  padding: 1rem
  flex-direction: column
  justify-content: center
  @media (min-width: $big-tablet)
    padding: $paddingDesktopPage !important
  .titleContainer
    display: flex
    justify-content: center
    width: 100%
    margin-bottom: 1rem
    h2
      margin-bottom: 0
  .gridContainer
    padding: 12px
    justify-content: flex-start
    @media (min-width: $tablet)
      padding: 0
  .addRequestContainer
    display: flex
    justify-content: center
  .addRequestButton
    font-weight: $regular-font-weight
    font-size: $large-font-size
    text-transform: none
  .floatingButton
    position: fixed
    bottom: 10px
    right: 10px
  .requestCard
    width: 100%
    position: relative
    //border: 1px solid $darkblue-color
    border-radius: 8px !important
    //min-height: 55vh //with description
    //min-height: 33vh
    .coverImg
      height: 12rem
    .requestCardHeader
      padding: 28px 10px 10px 0
      span:first-child, span:nth-child(2)
        text-align: center
    .requestCardContent
      padding: 0 !important
      .requestCardContentText
        span
          font-weight: $medium-font-weight !important
        p
          font-weight: $regular-font-weight !important
    .requestCardActions
      display: flex
      justify-content: center
      flex-direction: column
      align-items: center
      margin-bottom: 2rem
    .showRequestDetailButton
      background-color: $blue-color !important
      border-radius: 25px !important
      color: #ffffff !important
      width: 50% !important
      box-shadow: none
      padding: 10px
      @media (min-width: $big-tablet)
        position: absolute
        bottom: 3vh
    .deleteButton
      border-radius: 25px !important
      width: 50% !important
      margin: 10px
    .deleteIconButton
      position: absolute
      top: 0
      right: 0
      svg
        color: red !important

.skeletonBox
  min-height: 14rem
  align-items: center
  justify-content: center
  padding: 16px
//margin: 1em !important
.requestRow
  transition: ease-in-out 0.15s
.requestRow:hover
  background-color: $blue-ligth-color-hover
  cursor: pointer
.requestThumbnailCellContainer
  height: 10rem
  .requestThumbnail
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.15), 0 0 6px rgba(0, 0, 0, 0.13)
    width: 100%
    height: 100%
    object-fit: cover
    border-radius: 12px
    object-position: center
  .requestThumbnail:hover
    cursor: pointer

.actionsContainer
  display: flex
  flex-direction: column
  align-items: flex-end
.sectionTitle
  @media (max-width: $tablet)
    display: flex
    justify-content: center
