@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"

.categoryCard
  //box-shadow: none !important
  position: relative
  transition: ease-in-out 0.55s
  border-radius: 10px !important
  .categoryImage
    visibility: hidden
    height: 0
    transition: opacity 0.5s ease-in
    -webkit-transition: opacity 0.5s ease-in
    -moz-transition: opacity 0.5s ease-in
    -o-transition: opacity 0.5s ease-in
    opacity: 0
  .cardContent
    position: absolute
    bottom: 0
    padding: 3px 12px
  .categoryTitle
    font-weight: bold !important
    color: $white-color !important
    font-size: $big-font-size
.categoryCard:hover
  box-shadow: 0 1px 18px 0 rgba(0,0,0,0.2), 0 1px 10px 0 rgba(0, 0, 0, 1), 0 2px 1px -1px rgba(0,0,0,0.12) !important