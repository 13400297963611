@import "../../styles/colors"
@import "../../styles/typography"

.snackContainer
  z-index: 999999 !important
.snackRoot
  z-index: 6000 !important
.success
  div
    background-color: $snack-success
    font-weight: bold
.warning
  div
    background-color: $snack-warning
    font-weight: bold
.error
  div
    background-color: $snack-error
    font-weight: bold
.snackButton
  color: #fff !important
  border-color: #fff !important

.snackText
  color: #ffffff !important