@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"

.myAlert
  .title, .subtitle
    text-align: center
  .actionsContainer
    display: flex
    justify-content: center
    .submitButton
      background-color: #ffffff
      color: $blue-color
    .cancelButton
      color: #ff0000 !important

  .additionalText
    margin: 1rem
    font-size: $small-font-size