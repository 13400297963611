@import "../../styles/colors"
@import "../../styles/typography"
@import "../../styles/responsive"
@import "../../styles/sizes"

@keyframes slide-up
  0%
    opacity: 0
    transform: translateY(20px)
    -moz-transform: translateY(20px)

  100%
    opacity: 1
    transform: translateY(0)
    -moz-transform: translateY(0)
    -o-transform: translateY(0)

@keyframes zoomIn
  0%
    opacity: 0
    transform: scale(0)
    -moz-transform: scale(0)
    -o-transform: scale(0)

  100%
    opacity: 1
    transform: scale(1)
    -moz-transform: scale(1)
    -o-transform: scale(1)


.root:hover
  transform: scale(1.05)

.root
  text-decoration: none
  position: fixed !important
  bottom: 1.5rem
  padding: 0 !important
  left: 1rem
  display: flex
  align-items: center
  transition: 0.15s !important

  //background: rgb(170 235 172 / 20%)
  //border-radius: 87px
  //box-shadow: 0 4px 30px rgb(0 0 0 / 10%)
  //backdrop-filter: blur(5px)
  //-webkit-backdrop-filter: blur(5px)
  //border-top-left-radius: 200px
  //border-bottom-left-radius: 200px
  @media (min-width: $tablet)
    z-index: 1800
  @media (max-width: $tablet)
    bottom: 1.20rem
    left: 0.9rem

.icon
  width: 3rem
  height: 3rem
  border-radius: 100px
  box-shadow: 0 20px 47px rgb(0 0 0 / 30%), 0 2px 10px rgb(0 0 0 / 22%)
  @media (max-width: $tablet)
    width: 2.75rem
    height: 2.75rem

.title
  font-size: 18px
  font-weight: bold
  color: #000
  margin-left: 0.5rem

  @media (max-width: $tablet)
    font-size: 15px
